import React, {Component} from 'react';
import {Tag} from 'antd';
import {Link, withRouter} from "react-router-dom";
import styles from "./Reviews.module.scss";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {reviewerGetReviews} from "../redux-actions/reviewer";
import {conference} from "../constants/strings";
import {NoPaddingCard} from "./Components/NoPaddingCard";
import {StyledTable} from "./Components/StyledTable";
import {Scrollbar} from "react-scrollbars-custom";

class Reviews extends Component {

    componentDidMount() {
        this.props.getReviews()
    }


    renderAuthors = (authors) => {
        if(!authors) return;

        return <ul className={styles.authorList}>{authors.map(author => <li
            key={author.id}>{author.lastName} {author.firstName}</li>)}</ul>
    }

    columns = [{
        title: 'Title',
        dataIndex: 'abstract.title',
        key: 'title',
        width: '50%',
        render: (title, record) => <span><Link to={`/reviews/${record.id}`}>{title}</Link></span>
    },
        {
            title: 'Authors',
            dataIndex: 'abstract.authors',
            render: authors => this.renderAuthors(authors),
            key: 'author',
            width: 200,
        },
        {
            title: 'Status',
            dataIndex: 'abstract.status',
            key: 'status',
            render: status => (
                <span>
                    <Tag color={conference.abstractStatusColor[status]} key={status}>{conference.abstractStatus[status]}</Tag>
                </span>
            ),
            width: 150,
        }, {
            title: 'Assign date',
            key: 'submissionDate',
            dataIndex: 'createdAt',
            width: 150,
            defaultSortOrder: 'descend',
            render: date => date.substring(0, 10),
            sorter: (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt),
        }, {
            title: 'Last update',
            key: 'updateDate',
            dataIndex: 'updatedAt',
            width: 160,
            defaultSortOrder: 'descend',
            render: date => date.substring(0, 10),
            sorter: (a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt),
        }];


    render() {
        return (
            <div>
                <NoPaddingCard title="Reviews">
                    <Scrollbar style={{height: 'calc(100vh - 235px)'}}>
                    <StyledTable
                        rowKey="id"
                        columns={this.columns}
                        dataSource={this.props.reviews}
                        pagination={false}
                    />
                    </Scrollbar>
                </NoPaddingCard>
            </div>
        );
    }

}

Reviews.propTypes = {};

const mapStateToProps = (state, ownProps) => ({
    reviews: state.reviewer.reviews
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getReviews: reviewerGetReviews,
    },
    dispatch,
)


Reviews = connect(mapStateToProps, mapDispatchToProps)(Reviews)
Reviews = withRouter(Reviews)
export default Reviews
