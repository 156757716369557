import React, {Component} from 'react';
import BasicLayout from "../../views/MainLayout";
import AbstractView from "../../fragments/AbstractView";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {clearAbstract, deleteAbstract, downloadAbstract, getAbstractById} from "../../redux-actions/author";
import ReviewView from "../../fragments/ReviewView";
import {conference} from "../../constants/strings";
import _ from "lodash-es"
import Actions from "../../forms/Actions";
import {If} from "babel-plugin-jsx-control-statements/macro"

class AbstractPage extends Component {
    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.getAbstractById(id)
    }

    render() {
        const {abstract, sessions, downloadAbstract} = this.props;
        const cardStyle = {marginTop: 20}

        const renderActions = conference.authorCanResponse.some(status => status ===_.get(abstract, 'status', ''))
        return (
            <BasicLayout>
                <AbstractView
                    abstract={abstract}
                    sessionTitle={_.get(sessions.find(s => s.acronym === abstract.session), 'title', '')}
                    downloadAbstract={downloadAbstract}
                />
                <ReviewView style={cardStyle} review={abstract.review}/>
                <If condition={renderActions}>
                    <Actions style={cardStyle} review={abstract.review} abstract={abstract}/>
                </If>
            </BasicLayout>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    user: state.auth.user,
    abstract: state.author.abstract,
    sessions: state.settings.sessions,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getAbstractById: getAbstractById,
        deleteAbstract: deleteAbstract,
        clearAbstract: clearAbstract,
        downloadAbstract: downloadAbstract,
    },
    dispatch,
)


AbstractPage = connect(mapStateToProps, mapDispatchToProps)(AbstractPage)
export default AbstractPage

