import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Spin, Table} from "antd";
import _ from "lodash-es/lodash.default";
import config from '../config'

class Documents extends Component {

    columns = [
        {
            title: 'Documents',
            dataIndex: 'name',
            key: 'name', sorter: (a, b) => a.lastName.localeCompare(b.lastName),
            render: (value, record, index) => (
                <span>
                    <a href={record.url}>{value}</a>
                </span>
            ),
        }
    ]


    render() {
        const {user} = this.props

        let documents = [];
        if(user.documents) {
            documents = user.documents.map((doc, index) => ({
                id: `doc-${index}`,
                name: doc,
                url: `${config.apiUrl}/users/${user.id}/documents/${doc}`
            }))
        }

        return (
            <div>
                    <Spin spinning={_.isEmpty(user)}>
                        <Table rowKey="id" columns={this.columns} dataSource={documents} pagination={false}/>
                    </Spin>
            </div>
        );
    }
}

Documents.propTypes = {
    user: PropTypes.object
};

export default Documents;
