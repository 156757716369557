import React from 'react';
import PropTypes from 'prop-types';
import {Col, Divider, Row} from "antd";
import _ from "lodash-es";
import {columnSizes} from "./styles";

function Details(props) {
    const {user} = props

    const fullName = `${_.get(user, 'firstName', '')} ${_.get(user, 'lastName', '')}`

    return (
        <div>
            <Divider>Participant details</Divider>
            <Row gutter={16}>
                <Col {...columnSizes.header}>
                    <div>Title:</div>
                </Col>
                <Col {...columnSizes.data}>
                    <div>{_.get(user, 'title', '')}</div>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...columnSizes.header}>
                    <div>Name:</div>
                </Col>
                <Col {...columnSizes.data}>
                    <div>{fullName}</div>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...columnSizes.header}>
                    <div>Email:</div>
                </Col>
                <Col {...columnSizes.data}>
                    <div><a
                        href={`mailto:${_.get(user, 'email', '')}`}>{_.get(user, 'email', '')}</a>
                    </div>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col {...columnSizes.header}>
                    <div>Affiliation:</div>
                </Col>
                <Col {...columnSizes.data}>
                    <div>{_.get(user, 'affiliation', '')}</div>
                </Col>
            </Row>
        </div>
    );
}

Details.propTypes = {
    user: PropTypes.object,
};
Details.defaultProps = {};

export default Details;
