import React from 'react';
import NoMenuLayout from "../views/NoMenuLayout";
import ResetPasswordForm from "../forms/ResetPassword";
import styles from "../views/NoMenuLayout.module.scss";


function ForgotPassword() {
    return (
        <NoMenuLayout>
            <div className={`${styles.centerPanel} ${styles.panelResetPassword}`}>
                <div className={styles.panel}>
                    <ResetPasswordForm/>
                </div>
            </div>
        </NoMenuLayout>
    );
}

ForgotPassword.propTypes = {};
ForgotPassword.defaultProps = {};

export default ForgotPassword;
