import React from "react";
import {Alert, Form, Select, Switch} from 'antd';
import styled from 'styled-components'
import _ from 'lodash-es'
import dayjs from "dayjs";

const { Option } = Select;
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const StyledFormItem = styled(Form.Item)`
    margin-bottom: 0;
`


const FeeStatusForm = ({form, initialData}) => {

    const {getFieldDecorator} = form;

    return (
        <>
            <Alert style={{marginBottom: 10}} message="For safety reasons, further changes will not be possible after accepting the payment." type="warning" />

            <Form layout="horizontal">
                        <StyledFormItem label="Paid" {...layout}>
                            {getFieldDecorator('paid',
                                {
                                    initialValue: initialData.paid,
                                    valuePropName: 'checked',
                                }
                                )(<Switch disabled={initialData.paid} />)}
                        </StyledFormItem>
                        <StyledFormItem label="Payment type" {...layout}>
                            {getFieldDecorator('type', {
                                initialValue: initialData.type,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Fee type is required',
                                    },
                                ],
                            })(
                                <Select disabled={initialData.paid}>
                                    <Option value="FULL_EARLY">Full early</Option>
                                    <Option value="FULL_LATE">Full late</Option>
                                    <Option value="STUDENT_EARLY">Student early</Option>
                                    <Option value="STUDENT_LATE">Student late</Option>
                                    <Option value="STAFF">Technical staff</Option>
                                    <Option value="INVITED">Invited</Option>
                                    <Option value="CASH">Cash on desk</Option>
                                </Select>,
                            )}
                        </StyledFormItem>
                        <StyledFormItem label="Changed by" {...layout}>
                            <span className="ant-form-text">{_.get(initialData, 'editedBy.firstName', '') + " " + _.get(initialData, 'editedBy.lastName', '')}</span>
                        </StyledFormItem>
                        <StyledFormItem label="Modified date" {...layout}>
                            <span className="ant-form-text">{dayjs(initialData.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</span>
                        </StyledFormItem>

            </Form>
        </>
    )
};

export default Form.create()(FeeStatusForm)
