import React from 'react';
import PropTypes from 'prop-types';
import {Col, Divider, Row} from "antd";
import styles from "./UserView.module.scss";
import _ from "lodash-es";
import {getName} from "country-list";
import {columnSizes} from "./styles";

function Invoice(props) {
    const {user} = props

    return (
        <div> <Divider>Mailing address</Divider>
            <Row gutter={16}>
                <Col {...columnSizes.header}>
                    <div>Address:</div>
                </Col>
                <Col {...columnSizes.data}>
                    <div
                        className={styles.replaceEndlineWithLineBreaks}>{_.get(user, 'address', '')}</div>
                </Col>
            </Row>
            {/*<Row gutter={16}>*/}
            {/*    <Col {...columnSizes.header}>*/}
            {/*        <div>Tax (VAT) number:</div>*/}
            {/*    </Col>*/}
            {/*    <Col {...columnSizes.data}>*/}
            {/*        <div>{_.get(user, 'taxNumber', '')}</div>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Row gutter={16}>
                <Col {...columnSizes.header}>
                    <div>Country:</div>
                </Col>
                <Col {...columnSizes.data}>
                    <div>{getName(_.get(user, 'country', ''))}</div>
                </Col>
            </Row></div>
    );
}

Invoice.propTypes = {
    user: PropTypes.object,
};
Invoice.defaultProps = {};

export default Invoice;
