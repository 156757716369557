import React from 'react';
import {Checkbox, Col, Form, Row} from "antd";

import PropTypes from 'prop-types'
import _ from "lodash-es";

const SurveyForm = ({colOffset, user, form}) => {

    const {getFieldDecorator} = form;
    const colStyle = {marginBottom: -30};

    return (
        <Row>
            <Col offset={colOffset} xs={24 - 2 * colOffset}>
                <Form layout="horizontal">

                    <Row>
                        <Col xs={24} style={colStyle}>
                            <Form.Item>
                                {getFieldDecorator('acceptanceLetter', {
                                    valuePropName: 'checked',
                                    initialValue: _.get(user, 'acceptanceLetter', false),
                                })(<Checkbox>Please send me a Acceptance Letter by email.</Checkbox>)
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} style={colStyle}>
                            <Form.Item>
                                {getFieldDecorator('invitationLetter', {
                                    valuePropName: 'checked',
                                    initialValue: _.get(user, 'invitationLetter', false),
                                })(<Checkbox>Please send me a Invitation Letter for a Visa Application.</Checkbox>)
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} style={colStyle}>
                            <Form.Item>
                                {getFieldDecorator('certificate', {
                                    valuePropName: 'checked',
                                    initialValue: _.get(user, 'certificate', false),
                                })(<Checkbox>I need certificate of attendance.</Checkbox>)
                                }
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Col>
        </Row>
    );
}

SurveyForm.propTypes = {
    user: PropTypes.object,
    action: PropTypes.func,
    colOffset: PropTypes.number,
};

SurveyForm.defaultProps = {
    colOffset: 2
}

export default Form.create({})(SurveyForm);
