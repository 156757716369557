import React, {useState} from 'react';
import {Col, Form, Input, Row, Select} from 'antd';
import _ from 'lodash-es'
import {If} from "babel-plugin-jsx-control-statements/macro"
import PropTypes from 'prop-types'
import config from "../../config";
import notAllowAllCapital from "../../validators/notAllowAllCapital";

const FormItem = Form.Item;

const {registrationFormText} = config;

const CredentialsForm = ({user, form, colOffset}) => {

    const [confirmDirty, setConfirmDirty] = useState(false);

    const handleConfirmBlur = (e) => {
        const value = e.target.value;
        setConfirmDirty(confirmDirty || !!value);
    };

    const compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter are inconsistent!');
        } else {
            callback();
        }
    };

    const validateToNextPassword = (rule, value, callback) => {
        if (value && confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        callback();
    };


    const {getFieldDecorator} = form;

    return (
        <Form layout="vertical">
            <Row>
                <Col offset={colOffset} xs={24 - 2 * colOffset}>
                    <If condition={!user.id}>
                        <Row>
                            <Col xs={24}>
                                <p style={{textAlign: 'justify', marginBottom: 30, marginTop: 20}}>
                                    {registrationFormText}
                                </p>
                            </Col>
                        </Row>
                    </If>

                    <Row>
                        <Col xs={8}>
                            <FormItem
                                label="E-mail"
                            >
                                {getFieldDecorator('email', {
                                    rules: [{
                                        type: 'email', message: 'The input is not valid E-mail!',
                                    }, {
                                        required: true, message: 'Please input your E-mail!',
                                    }],
                                    initialValue: _.get(user, 'email', '')
                                })(
                                    <Input autoComplete="off"/>
                                )}
                            </FormItem>
                        </Col>
                        <Col/>

                        <Col xs={7} offset={1}>
                            <FormItem
                                label="Password"
                            >
                                {getFieldDecorator('password', {
                                    rules: [{
                                        required: _.isUndefined(user.id),
                                        message: 'Please input your password!',
                                    }, {
                                        validator: validateToNextPassword,
                                    },
                                        {
                                            min: 6, message: 'Password should have at least 6 characters'
                                        }],
                                })(
                                    <Input type="password" autoComplete="new-password"/>
                                )}
                            </FormItem>
                        </Col>

                        <Col xs={7} offset={1}>
                            <FormItem
                                label="Confirm Password"
                            >
                                {getFieldDecorator('confirm', {
                                    rules: [{
                                        required: _.isUndefined(user.id),
                                        message: 'Please confirm your password!',
                                    }, {
                                        validator: compareToFirstPassword,
                                    }],
                                })(
                                    <Input type="password" onBlur={handleConfirmBlur} autoComplete="new-password"/>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <FormItem
                                label="Title"
                            >
                                {getFieldDecorator('title', {
                                    rules: [
                                        {required: true, message: 'Please select your title'},
                                    ],
                                    initialValue: _.get(user, 'title', '')
                                })(
                                    <Select>
                                        <Select.Option value="Eng">Eng.</Select.Option>
                                        <Select.Option value="MSc">MSc</Select.Option>
                                        <Select.Option value="PhD">PhD</Select.Option>
                                        <Select.Option value="Prof">Prof.</Select.Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>

                        <Col xs={9} offset={1}>
                            <FormItem
                                label={(
                                    <span>First name</span>
                                )}
                            >
                                {getFieldDecorator('firstName', {
                                    rules: [{
                                        required: true,
                                        message: 'Please input your first name!',
                                        whitespace: true
                                    }, {
                                        validator: notAllowAllCapital
                                    }],
                                    initialValue: _.get(user, 'firstName', '')
                                })(
                                    <Input/>
                                )}
                            </FormItem>
                        </Col>
                        <Col xs={9} offset={1}>
                            <FormItem
                                label={(<span>Last name</span>)}>

                                {getFieldDecorator('lastName', {
                                    rules: [{
                                        required: true,
                                        message: 'Please input your last name!',
                                        whitespace: true
                                    }, {
                                        validator: notAllowAllCapital
                                    }],
                                    initialValue: _.get(user, 'lastName', '')
                                })(
                                    <Input/>
                                )}
                            </FormItem>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24}>

                            <Form.Item
                                label={(<span>Affiliation</span>)}>

                                {getFieldDecorator('affiliation', {
                                    rules: [{
                                        required: true,
                                        message: 'Please input your affiliation!',
                                        whitespace: true
                                    }],
                                    initialValue: _.get(user, 'affiliation', '')
                                })(
                                    <Input/>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                </Col>
            </Row>

        </Form>
    );
};

CredentialsForm.defaultProps = {
    colOffset: 2
};

CredentialsForm.propTypes = {
    formContent: PropTypes.object,
    user: PropTypes.object,
    colOffset: PropTypes.number
};


export default Form.create({})(CredentialsForm);
