import React, {useEffect} from 'react'
import UsersStatsPanel from "./UsersStatsPanel";
import AbstractsStatsPanel from "./AbstractsStatsPanel";
import EventsStatsPanel from "./EventsStatsPanel";
import {bindActionCreators} from "redux";
import {editorGetAbstractsStats, editorGetUsersByCountry, editorGetUsersByEvent} from "../../../redux-actions/editor";
import {connect} from "react-redux";

const StatsPanel = ({usersByCountry, usersByEvent, abstractsBySession, sessions, editorGetUsersByCountry, editorGetUsersByEvent, editorGetAbstractsStats}) => {

    useEffect(() => {
        console.log("Effect")
        editorGetUsersByCountry();
        editorGetUsersByEvent();
        editorGetAbstractsStats();
    }, [editorGetUsersByCountry, editorGetUsersByEvent, editorGetAbstractsStats ]);

    return (
        <>
        <UsersStatsPanel data={usersByCountry}/>
        <AbstractsStatsPanel data={abstractsBySession} sessions={sessions}/>
        <EventsStatsPanel data={usersByEvent}/>
        </>
    )
};

const mapStateToProps = (state, ownProps) => ({
    usersByCountry: state.editor.usersByCountry,
    usersByEvent: state.editor.usersByEvent,
    abstractsBySession: state.editor.abstractsBySession,
    sessions: state.settings.sessions,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        editorGetUsersByCountry,
        editorGetUsersByEvent,
        editorGetAbstractsStats
    },
    dispatch,
);


export default connect(mapStateToProps, mapDispatchToProps)(StatsPanel)

