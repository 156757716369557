export const columnSizes = {
    header: {
        xs: 24,
        md: 24,
        xl: 6
    },
    data: {
        xs: 24,
        md: 24,
        xl: 18
    }
}