import BasicLayout from "../../views/MainLayout";
import React from "react";
import {Settings} from "../../fragments/Editor/Settings";

const AdminSettings = (props) => {

    return (
        <BasicLayout>
            <Settings></Settings>
        </BasicLayout>
    )
}

export default AdminSettings
