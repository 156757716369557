import React, {Component} from 'react';
import {Button, Col, notification, Row, Steps} from "antd";
import CredentialsForm from "./CredentialsForm";
import AddressForm from "./AddressForm";
import SurveyForm from "./SurveyForm";
import _ from 'lodash-es'
import {If} from "babel-plugin-jsx-control-statements/macro"
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";

class Registration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            formContent: {}
        };

        this.formsRefs = new Array(3)
    }


    wizardSteps = () => {
        const {country} = this.props;

        return [{
            title: 'Author information',
            content: <CredentialsForm user={this.state.formContent} ref={form => (this.formsRefs[0] = form)}/>,
        }, {
            title: 'Mailing address',
            content: <AddressForm user={this.state.formContent}  country={country} ref={form => (this.formsRefs[1] = form)}/>,
        }, {
            title: 'Survey',
            content: <SurveyForm user={this.state.formContent}  ref={form => (this.formsRefs[2] = form)}/>,
        }];
    };

    handleNext = () => {
        const step = this.state.current;
        this.formsRefs[step].validateFields((err, values) => {
            if(!err){
                const formContent = this.state.formContent;
                const current = step + 1;
                this.setState({current, formContent: {...formContent, ...values}})
            }
        })
    };


    prev = () => {
        const current = this.state.current - 1;
        this.setState({ current });
    };


    handleRegister = () => {
        this.formsRefs[2].validateFields((err, values) => {
            if(!err){

                this.setState({navigationDisabled: true});
                const formContent = {...this.state.formContent, ...values};

                this.props.userRegister(formContent).then((response) => {
                    notification['success']({
                        message: 'Success',
                        description: 'Registration completed. Now you can log in.',
                    });
                    _.delay(() => this.props.history.push('/'), 1500)
                }).catch((failed) => {
                    let msg = _.upperFirst(_.get(failed, 'error.response.data.message', ''));
                    notification['error']({
                        message: 'Error',
                        description: `Cannot register. ${msg}`,
                    });

                    this.setState({navigationDisabled: false})
                })
            }
        })
    };

    render() {
        const { current } = this.state;
        const steps = this.wizardSteps();
        return (
            <div>
                <Steps current={current}>
                    {steps.map(item => <Steps.Step key={item.title} title={item.title}/>)}
                </Steps>
                <div>{steps[current].content}</div>
                <div>
                    <Row>
                        <Col offset={2} xs={10}>
                            <If condition={current > 0}>
                                <Button onClick={() => this.prev()}>
                                    Previous
                                </Button>
                            </If>
                        </Col>

                        <Col xs={10}>

                            <If condition={current < steps.length - 1}>
                                <Button style={{float: 'right'}} type="primary"
                                        onClick={this.handleNext}>Next</Button>
                            </If>
                            <If condition={current === steps.length - 1}>
                                <Button style={{float: 'right'}} onClick={this.handleRegister} disabled={this.state.navigationDisabled} type="primary">Register</Button>
                            </If>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

Registration.propTypes = {
    onFormEdit: PropTypes.func.isRequired,
    formContent: PropTypes.object,
    user: PropTypes.object,
    country: PropTypes.string,
};

Registration = withRouter(Registration);
export default Registration
