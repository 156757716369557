import React, {Component} from 'react';
import {Button, Card, Col, Icon, Row, Spin} from "antd";
import _ from 'lodash-es'
import styles from './Abstract.module.scss'
import {withRouter} from "react-router-dom";
import download from 'downloadjs'
import cd from "content-disposition";


const AuthorView = ({author, isPresenter}) => {
    const {firstName, lastName, affiliation, email} = author;
    return (
        <span selected={isPresenter}>
            { isPresenter && <Icon type="sound" style={{marginRight: 5}} /> }
            {firstName} {lastName}, {affiliation}, <a href={`mailto:${email}`}>{email}</a>
        </span>
    )
}


class AbstractView extends Component {

    renderAuthors = (authors, presenterIdx) => {
        return (
            <ul className={styles.authorList}>
                {authors.map((author, idx) => {
                        return (<li key={author.id}>
                            <AuthorView author={author} isPresenter={idx === presenterIdx}/>
                        </li>)
                    }
                )}</ul>
        )
    };

    render() {
        const {abstract, sessionTitle, extraButtons, downloadAbstract} = this.props;

        let authors = [];
        let keywords = '';
        if (!_.isEmpty(abstract)) {
            authors = this.renderAuthors(abstract.authors, abstract.presenter);
            keywords = _.get(abstract, 'keywords', []).join(', ');
        }

        const columnSizes = {
            header: {
                xs: 24,
                md: 6,
                xl: 2
            },
            data: {
                xs: 24,
                md: 18,
                xl: 22
            }

        };

        return (
          <div>
              <Card
                  title="Abstract"
                  extra={extraButtons}
              >
                  <Spin spinning={_.isEmpty(abstract)}>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Title:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div>{_.get(abstract, 'title', '')}</div>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Authors:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div>{authors}</div>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Keywords:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div>{keywords}</div>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Session:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <div>{sessionTitle}</div>
                          </Col>
                      </Row>
                      <Row gutter={16}>
                          <Col className={styles.header} {...columnSizes.header}>
                              <div>Abstract:</div>
                          </Col>
                          <Col {...columnSizes.data}>
                              <Button style={{padding: 0}} size="small" type="link" icon="cloud-download" onClick={() => downloadAbstract(abstract).then(response => {
                                  const disposition = cd.parse(response.payload.headers['content-disposition'])
                                  download(response.payload.data, disposition.parameters.filename);
                              })}> See abstract</Button>
                          </Col>
                      </Row>
                  </Spin>
              </Card>

          </div>
        );
    }
}


export default withRouter(AbstractView)
