import React from "react";
import {Icon, Upload} from "antd";
import cd from "content-disposition";
import download from "downloadjs";

const {Dragger} = Upload;


export const TemplateUpload = ({title, uploadAction, deleteAction, downloadAction, template, fileInfo}) => {

    let fileList = []
    if (fileInfo && fileInfo.exist) {
        fileList = [{
            name: fileInfo.filename,
            //url: `${config.apiUrl}/settings/templates/${template}/download`,
            uid: template,
            status: 'done'
        }]
    }

    const uploaderProps = {
        multiple: false,
        accept: '.docx',
        onRemove: file => {
            // Empty the files list because this is single upload!
            deleteAction(template);
        },
        customRequest: ({file, onSuccess}) => {
            deleteAction(template).finally(response => {
                uploadAction(file, template).then((response) => onSuccess())
            })
        },
        onDownload: (file) => {
            downloadAction(file.uid).then(response => {
                const disposition = cd.parse(response.payload.headers['content-disposition'])
                download(response.payload.data, disposition.parameters.filename);
            })
        },
        fileList
    };

    return (
        <Dragger {...uploaderProps}>
            <p className="ant-upload-drag-icon">
                <Icon type="file-word"/>
            </p>
            <p className="ant-upload-text">{title}</p>
        </Dragger>
    );
};
