import React from 'react';
import {Button, Card, Col, Form, Input, Radio, Row} from 'antd';
import PropTypes from 'prop-types'
import {withRouter} from "react-router-dom";
import {conference} from "../constants/strings";

const FormItem = Form.Item;

class Review extends React.Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.action({
                    id: this.props.review.id,
                    comment: values.comment,
                    decision: values.decision
                }).then((response) => {
                    this.props.history.push('/reviews')
                })
            }
        });
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = {
            labelCol: {
                sm: {span: 24},
            },
            wrapperCol: {
                sm: {span: 24},
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                sm: {span: 22},
            },
        };
        getFieldDecorator('keys', {initialValue: [{}]});

        const decisionOptions = conference.reviewDecision.map(decision => <Radio
            value={decision} key={decision}>{conference.abstractStatus[decision]}</Radio>)
        return (
            <div>
                <Card title="Review" style={{...this.props.style}} >
                    <Form onSubmit={this.handleSubmit} layout='vertical'>
                        <Row>
                            <Col xs={4}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Decision"
                                >
                                    {getFieldDecorator('decision', {
                                        rules: [
                                            {required: true, message: 'Please select your decision!'},
                                        ],
                                    })(
                                        <Radio.Group>
                                            {decisionOptions}
                                        </Radio.Group>
                                    )}
                                </FormItem>
                            </Col>
                            <Col xs={20}>
                                <FormItem
                                    {...formItemLayout}
                                    label="Comments"
                                >
                                    {getFieldDecorator('comment', {
                                    })(
                                        <Input.TextArea rows={3}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <FormItem {...formItemLayoutWithOutLabel}>
                                <Button type="primary" htmlType="submit">Submit</Button>
                            </FormItem>
                        </Row>
                    </Form>
                </Card>
            </div>
        );
    }
}

Review.propTypes = {
    action: PropTypes.func.isRequired,
    review: PropTypes.object.isRequired
}

Review = Form.create()(Review);
Review = withRouter(Review)
export default Review

