import React, {Component} from 'react';
import {Card, Icon, Tag, Timeline, Tooltip} from "antd";
import _ from "lodash-es";
import {conference} from "../constants/strings";
import {If} from "babel-plugin-jsx-control-statements/macro"

class ReviewView extends Component {
    render() {

        const {review} = this.props;

        const comments = _.get(review, 'comments', [])
        let timeline = comments.map((comment) => {
            const statusColor = conference.abstractStatusColor[comment.status] || "rgba(0, 0, 0, 0.5)";
            const iconStyle = {fontSize: '16px', color: statusColor}

            return <Timeline.Item
                key={comment.id}
                dot={<Icon type={comment.author === 'REVIEWER' ? 'right-circle' : 'left-circle'}
                           style={{...iconStyle}}
                />}>
                <Tag color={statusColor}>
                    {_.get(comment, 'createdAt', '').substring(0, 10)}
                </Tag>
                <If condition={comment.status}>
                    <Tooltip placement="top" title={conference.abstractStatusTip[comment.status]}>
                        <Tag color={statusColor}>
                            {conference.abstractStatus[comment.status]}
                        </Tag>
                    </Tooltip>
                </If>
                <p style={{marginBottom: 0}}>{_.get(comment, 'text')}</p>
            </Timeline.Item>
        })

        console.log(review);
        const title = review?.reviewer?.id ? `Review (${review.reviewer.firstName} ${review.reviewer.lastName})` : 'Review';
        return (
            <div>
                <Card title={title} style={{...this.props.style}}>
                    {/*<Spin spinning={_.isEmpty(review)}>*/}
                    <Timeline>
                        {timeline}
                    </Timeline>
                    {/*</Spin>*/}
                </Card>
            </div>
        );
    }
}

export default ReviewView;
