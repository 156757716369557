import React, {Component} from 'react';
import BasicLayout from "../../views/MainLayout";
import Abstracts from "../../lists/Abstracts";
import {bindActionCreators} from "redux";
import {deleteAbstract, getAbstracts} from "../../redux-actions/author";
import connect from "react-redux/es/connect/connect";


class AbstractsPage extends Component {

    render() {
        const {abstracts, getAbstracts, deleteAbstract} = this.props
        return (
            <BasicLayout>
                <Abstracts abstracts={abstracts} getAbstracts={getAbstracts} deleteAbstract={deleteAbstract}/>
            </BasicLayout>
        );
    }

    componentDidMount() {
        this.props.getAbstracts()
    }
}

const mapStateToProps = (state, ownProps) => ({
    abstracts: state.author.abstracts
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getAbstracts: getAbstracts,
        deleteAbstract: deleteAbstract
    },
    dispatch,
)


AbstractsPage = connect(mapStateToProps, mapDispatchToProps)(AbstractsPage)

export default AbstractsPage;
