import React, {PureComponent} from 'react';
import NoMenuLayout from "../views/NoMenuLayout";
import styles from "../views/NoMenuLayout.module.scss";
import {Col, Icon} from "antd";

class NotFound extends PureComponent {
    render() {
        return (

            <NoMenuLayout>
            <div className={`${styles.centerPanel} ${styles.panelNotFound}`}>
                <div className={styles.panel}>
                    <Col xs={4}><Icon type="frown" className={styles.iconNotFound} /></Col>
                    <Col xs={20}>
                            <p style={{fontSize: '2em', padding: 0, margin: 0, fontWeight: "bold"}}>
                                We are sorry!
                            </p>
                            <p style={{fontSize: '1.6em', padding: 0, margin: 0}}>
                                We can't seem to find the page your're looking for.
                            </p>
                     </Col>
                </div>
            </div>
            </NoMenuLayout>

        );
    }
}


export default NotFound;
