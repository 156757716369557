import {
    API_EDITOR_CLEAR_ABSTRACTS,
    API_EDITOR_CLEAR_USERS,
    API_EDITOR_COUNT_USERS,
    API_EDITOR_DELETE_ABSTRACT,
    API_EDITOR_DELETE_TEMPLATE,
    API_EDITOR_GET_ABSTRACT,
    API_EDITOR_GET_ABSTRACTS_STATS,
    API_EDITOR_GET_ALL_ABSTRACTS,
    API_EDITOR_GET_REVIEWERS,
    API_EDITOR_GET_USER,
    API_EDITOR_GET_USERS,
    API_EDITOR_GET_USERS_BY_COUNTRY,
    API_EDITOR_GET_USERS_BY_EVENT,
    API_EDITOR_INDEX_TEMPLATES,
    API_EDITOR_SET_USER_FEE,
    API_EDITOR_SET_USER_ROLE,
    API_EDITOR_UPDATE_USER_ADDRESS,
    API_EDITOR_UPDATE_USER_CREDENTIALS,
    API_EDITOR_UPDATE_USER_SURVEY,
    API_EDITOR_UPLOAD_TEMPLATE,
    API_UPDATE_USER,
    API_UPDATE_USER_ADDRESS,
    API_UPDATE_USER_EVENTS,
    API_UPDATE_USER_SURVEY,
    EDITOR_CHANGE_USERS_LIST_PAGE, EDITOR_SET_LAST_EDITED_ABSTRACT_ID, EDITOR_SET_LAST_EDITED_USER_ID
} from "../constants/action-types";

const initialState = {
    abstracts: [],
    abstract: {},
    reviewers: [],
    users: [],
    user: {},
    usersCounter: {},
    usersByCountry: {},
    usersByEvent: {},
    abstractsBySession: [],
    templates: {},
    editedUser: {},
    lastEditedUserId: '',
    lastEditedAbstractId: '',
};

const editorReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${API_EDITOR_GET_ALL_ABSTRACTS}_SUCCESS`:
            return {
                ...state, abstracts: action.payload.data
            }
        case API_EDITOR_CLEAR_ABSTRACTS:
            return {
                ...state, abstracts: []
            }
        case `${API_EDITOR_GET_ABSTRACT}_SUCCESS`:
            return {
                ...state, abstract: action.payload.data
            }
        case `${API_EDITOR_GET_REVIEWERS}_SUCCESS`:
            return {
                ...state, reviewers: action.payload.data
            }
        case `${API_EDITOR_GET_USERS}_SUCCESS`:
            return {
                ...state, users: action.payload.data
            }
        case API_EDITOR_CLEAR_USERS:
            return {
                ...state, users: []
            }
        case `${API_EDITOR_SET_USER_ROLE}_SUCCESS`:
            return {
                ...state
            }
        case `${API_UPDATE_USER_SURVEY}_SUCCESS`:
        case `${API_UPDATE_USER}_SUCCESS`:
        case `${API_UPDATE_USER_ADDRESS}_SUCCESS`:
        case `${API_UPDATE_USER_EVENTS}_SUCCESS`:
            // TODO: Remove this, huge shit!
            return {
                ...state, user: action.payload.data
            }
        case `${API_EDITOR_SET_USER_FEE}_SUCCESS`:
            return {
                ...state
            }
        case `${API_EDITOR_DELETE_ABSTRACT}_SUCCESS`:
            return {
                ...state, abstracts: state.abstracts.filter((abstract) => abstract.id !== action.meta.previousAction.payload.id)
            }
        case `${API_EDITOR_COUNT_USERS}_SUCCESS`:
            return {
                ...state, usersCounter: action.payload.data
            }
        case EDITOR_CHANGE_USERS_LIST_PAGE:
            return {
                ...state, currentUsersListPage: action.page
            }
        case `${API_EDITOR_GET_USERS_BY_COUNTRY}_SUCCESS`:
            return {
                ...state, usersByCountry: action.payload.data
            }
        case `${API_EDITOR_GET_ABSTRACTS_STATS}_SUCCESS`:
            return {
                ...state, abstractsBySession: action.payload.data
            }
        case `${API_EDITOR_GET_USERS_BY_EVENT}_SUCCESS`:
            return {
                ...state, usersByEvent: action.payload.data
            }
        case `${API_EDITOR_INDEX_TEMPLATES}_SUCCESS`:
            return {
                ...state, templates: action.payload.data
            }
        case `${API_EDITOR_UPLOAD_TEMPLATE}_SUCCESS`:
            return {
                ...state,
                templates: Object.assign({}, state.templates, {
                    [action.meta.previousAction.template]: {
                        exist: true,
                        filename: action.payload.data.filename
                    }
                })
            }
        case `${API_EDITOR_DELETE_TEMPLATE}_SUCCESS`:
              return {
                ...state, templates: Object.assign({}, state.templates, {
                    [action.meta.previousAction.template]: {
                        exist: false
                    }
                })
            }

        case `${API_EDITOR_GET_USER}_SUCCESS`:
        case `${API_EDITOR_UPDATE_USER_CREDENTIALS}_SUCCESS`:
        case `${API_EDITOR_UPDATE_USER_ADDRESS}_SUCCESS`:
        case `${API_EDITOR_UPDATE_USER_SURVEY}_SUCCESS`:
            return {
                ...state, editedUser: action.payload.data
            };
        case EDITOR_SET_LAST_EDITED_USER_ID:
            return {
                ...state, lastEditedUserId: action.userId,
            };
        case EDITOR_SET_LAST_EDITED_ABSTRACT_ID:
            return {
                ...state, lastEditedAbstractId: action.abstractId,
            };
        default:
            return state

    }
}

export default editorReducer;
