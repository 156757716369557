import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import BasicLayout from "../../views/MainLayout";
import React, {useEffect} from "react";
import {
    editorAcceptGeneratedDocument,
    editorCreateFromTemplate,
    editorDeclineGeneratedDocument,
    editorDeleteUser,
    editorGetUserById,
    editorRemoveDocument,
    editorUpdateUserAddress,
    editorUpdateUserCredentials,
    editorUpdateUserSurvey
} from "../../redux-actions/editor";
import UserView from "../../fragments/UserView/UserView";
import DocumentsForm from "../../forms/Documents";
import {useHistory, useParams} from 'react-router-dom'
import {Tabs} from "antd";

const {TabPane} = Tabs;

const AdminUserView = (props) => {
    const {id, tab} = useParams();
    let history = useHistory();

    const {user, auth} = props;
    const {editorUpdateUserCredentials, editorUpdateUserAddress, editorUpdateUserSurvey, editorGetUserById, editorDeleteUserById} = props;

    useEffect(() => {
        editorGetUserById(id);
    }, [id, editorGetUserById]);

    const onChangeTab = (activeKey) => {
        history.push({
            pathname: `/editor/users/${id}/${activeKey}`
        });
    };



        return (
            <BasicLayout>
                <Tabs animated={false} activeKey={tab || 'participant'} onChange={onChangeTab}>
                    <TabPane tab="Participant" key="participant">
                        <UserView user={user}
                                  allowEdit={true}
                                  userCredentialsUpdate={editorUpdateUserCredentials}
                                  userAddressUpdate={editorUpdateUserAddress}
                                  userSurveyUpdate={editorUpdateUserSurvey}
                                  showActions={true}
                                  userRemove={editorDeleteUserById}
                                  />
                    </TabPane>
                    <TabPane tab="Documents" key="documents">
                        <DocumentsForm
                            user={user}
                            auth={auth}
                            removeAction={props.editorRemoveDocument}
                            generateDocument={props.editorCreateFromTemplate}
                            acceptDocument={props.editorAcceptGeneratedDocument}
                            declineDocument={props.editorDeclineGeneratedDocument}
                        />
                    </TabPane>
                </Tabs>
            </BasicLayout>
        );
};

const mapStateToProps = (state, ownProps) => ({
    user: state.editor.editedUser,
    auth: state.auth,
    formContent : state.auth.userForm
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        editorGetUserById,
        editorRemoveDocument,
        editorUpdateUserCredentials,
        editorUpdateUserSurvey,
        editorUpdateUserAddress,
        editorCreateFromTemplate,
        editorAcceptGeneratedDocument,
        editorDeclineGeneratedDocument,
        editorDeleteUserById: editorDeleteUser
    },
    dispatch,
);


export default connect(mapStateToProps, mapDispatchToProps)(AdminUserView)

