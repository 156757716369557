import React from "react";
import {Typography} from "antd";

const {Text, Paragraph, Title} = Typography;

export const HowTo = () => {
    return (
        <Typography>
            <Title level={4}>Howto</Title>
            <Paragraph>
                <Text>Here you can add templates on the basis of which the system will prepare personalized
                    documents for the participants.</Text>
                <br/>
                <Text>The following tags are currently supported in Microsoft Word (docx) files:</Text>
            </Paragraph>
            <Paragraph>
                <ul>
                    <li><strong>{'{name}'}</strong> - first and last name - will be retrieved from the participant's profile,
                    </li>
                    <li><strong>{'{affiliation}'}</strong> - will be retrieved from the participant's profile,
                    </li>
                    <li><strong>{'{address}'}</strong> - will be retrieved from the participant's profile,</li>
                    <li><strong>{'{country}'}</strong> - will be retrieved from the participant's profile,</li>
                    <li><strong>{'{title}'}</strong> - will be retrieved from the participant's profile,</li>

                    <li><strong>{'{passport number}'}</strong> - must be given at the generation stage,</li>
                    <li><strong>{'{passport expiration date}'}</strong> - must be given at the generation stage,</li>
                    <li><strong>{'{date of birth}'}</strong> - must be given at the generation stage.</li>
                </ul>
            </Paragraph>
            <Paragraph>
                <Text>The documents will be sent to the participant's account automatically after the editor's
                    approval. Documents can be created from the Participant Management Panel.</Text>
            </Paragraph>
        </Typography>
    )
}
