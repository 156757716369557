import React from 'react';
import ReactDOM from 'react-dom';
import whyDidYouRender from "@welldone-software/why-did-you-render";
import App from './App';
import registerServiceWorker from './registerServiceWorker';

if (process.env.NODE_ENV !== 'production') {
    whyDidYouRender(React, {
        onlyLogs: true,
        titleColor: "green",
        diffNameColor: "darkturquoise",
    });
}



registerServiceWorker();

ReactDOM.render(
    <App/>, document.getElementById('root'));
//registerServiceWorker();
