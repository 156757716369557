import React from 'react'

export default {
    title: 'subDemo 2025',
    subTitle: '1st International Conference on demonstrations',
    conferenceSiteUrl: 'http://example.com/',
    registrationFormText: (<span>Complete the form below in order to register for the subDemo 2025,
        November 1-15, 2020, Example, Example. If you experience any problems with the site, you may wish to try another browser.
        If you continue to have problems, please contact us at <a href="mailto:contact@example.com">contact@example.com</a></span>),
    feeTabVisible: true,
    // backgroundImage: '/background_mf2020.webp',
    abstractTemplate: '/abstract_Template.docx',
    feeText: (<div style={{textAlign: 'center'}}>
        <div>
            <p>
                <strong>Payments info.</strong><br/>
            </p>
        </div>
    </div>),
    apiUrl: process.env.NODE_ENV === 'production' ? 'https://demo.suberus.net/api' : 'http://localhost:9000/api',
}
