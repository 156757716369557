import {
    API_AUTHOR_DELETE_ABSTRACT, API_AUTHOR_DOWNLOAD_ABSTRACT,
    API_AUTHOR_GET_ABSTRACT,
    API_AUTHOR_GET_ABSTRACTS,
    API_AUTHOR_GET_KEYWORDS,
    API_AUTHOR_RESUBMIT_ABSTRACT,
    API_AUTHOR_SUBMIT_ABSTRACT,
    API_AUTHOR_UPDATE_ABSTRACT,
    AUTHOR_ADD_FIRST_AUTHOR_TO_ABSTRACT,
    AUTHOR_CLEAR_STORED_ABSTRACT,
} from "../constants/action-types";

export const submitAbstract = (file, meta) => {
    let data = new FormData();
    data.append('file', file);
    data.append('meta', JSON.stringify(meta))

    return {
        type: API_AUTHOR_SUBMIT_ABSTRACT,
        payload: {
            request: {
                url: `/abstracts`,
                method: 'post',
                headers: {
                    'content-type': 'multipart/form-data'
                },
                data
            }
        },
    }
}

export const getAbstracts = () => {
    return {
        type: API_AUTHOR_GET_ABSTRACTS,
        payload: {
            request: {
                url: `/abstracts`,
                method: 'get'
            }
        },
    }
}

export const getAbstractById = (id) => {
    return {
        type: API_AUTHOR_GET_ABSTRACT,
        payload: {
            request: {
                url: `/abstracts/${id}`,
                method: 'get'
            }
        },
    }
}

export const updateAbstract = (file, meta, abstractId) => {

    let data = new FormData();
    data.append('file', file);
    data.append('meta', JSON.stringify(meta));

    return {
        type: API_AUTHOR_UPDATE_ABSTRACT,
        payload: {
            request: {
                url: `/abstracts/${abstractId}`,
                method: 'put',
                headers: {
                    'content-type': 'multipart/form-data'
                },
                data
            }
        },
    }
};

export const resubmitAbstract = (file, meta, abstractId) => {
    let data = new FormData();
    data.append('file', file);
    data.append('meta', JSON.stringify(meta));

    return {
        type: API_AUTHOR_RESUBMIT_ABSTRACT,
        payload: {
            request: {
                url: `/abstracts/${abstractId}/resubmit`,
                method: 'put',
                headers: {
                    'content-type': 'multipart/form-data'
                },
                data
            }
        },
    }
}

export const deleteAbstract = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: API_AUTHOR_DELETE_ABSTRACT,
            payload: {
                request: {
                    url: `/abstracts/${id}`,
                    method: 'delete'
                }
            },
        }).then(() => dispatch(getAbstracts()))
    }
}


export const clearAbstract = () => {
    return {
        type: AUTHOR_CLEAR_STORED_ABSTRACT
    }
}

export const addFirstAuthor = (firstName, lastName, email, affiliation) => {
    return {
        type: AUTHOR_ADD_FIRST_AUTHOR_TO_ABSTRACT,
        user: {firstName, lastName, email, affiliation}
    }
}


export const searchKeywords = (keyword) => {
    return {
        type: API_AUTHOR_GET_KEYWORDS,
        payload: {
            request: {
                url: `/keywords/${keyword}`,
                method: 'get'
            }
        },
    }
}

export const downloadAbstract = (abstract) => {
    return {
        type: API_AUTHOR_DOWNLOAD_ABSTRACT,
        payload: {
            request: {
                url: `/abstracts/${abstract?.id}/download`,
                method: 'get',
                responseType: 'blob'
            }
        },
    }
}
