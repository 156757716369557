import {bindActionCreators} from "redux";
import {connect, shallowEqual, useDispatch, useSelector} from "react-redux";
import BasicLayout from "../../views/MainLayout";
import React, {useCallback, useEffect} from "react";
import AdminUsers from "../../lists/AdminUsers";
import qs from "query-string"
import { useLocation } from 'react-router-dom'
import {
    editorClearUsers,
    editorExportParticipants,
    editorGetUserFee,
    editorGetUsers, editorSetLastEditedUserId,
    editorSetUserFee,
    editorSetUserRole,
} from "../../redux-actions/editor";

const AdminUsersList = (props) => {

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const search = location.search;
        dispatch(editorGetUsers(qs.parse(search)))
    }, [dispatch, location]);

    useEffect(() => {
        return () => {
            dispatch(editorClearUsers());
        }
    }, [dispatch]);


    const users = useSelector(state => state.editor.users, shallowEqual);
    const lastId = useSelector(state => state.editor.lastEditedUserId);

    const handleGetUserFee = useCallback(
        (user) => dispatch(editorGetUserFee(user)),
        [dispatch]
    );

    const handleSetUserFee = useCallback(
        (user, paid, type) => dispatch(editorSetUserFee(user, paid, type)),
        [dispatch]
    );

    const handleSetUserRole = useCallback(
        (user, role) => dispatch(editorSetUserRole(user, role)),
        [dispatch]
    );

    const handleExportParticipants = useCallback(
        (user, role) => dispatch(editorExportParticipants()),
        [dispatch]
    );

    const handleSetLastVisitedId = useCallback(
        (userId) => dispatch(editorSetLastEditedUserId(userId)),
        [dispatch]
    );

     return (
            <BasicLayout>
                <AdminUsers
                    users={users}
                    getUserFee={handleGetUserFee}
                    setUserFee={handleSetUserFee}
                    setUserRole={handleSetUserRole}
                    exportUsers={handleExportParticipants}
                    lastId={lastId}
                    setLastId={handleSetLastVisitedId}
                />
            </BasicLayout>
        );
};

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        editorGetUsers,
        editorGetUserFee,
        editorSetUserFee,
        editorSetUserRole,
        editorClearUsers,
        editorExportParticipants,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsersList)

