import React, {useEffect} from "react";
import {Col, Row, Typography} from "antd";
import {HowTo} from "./Howto";
import {TemplateUpload} from "./TemplateUpload";

import {GenerateTestButton} from "./GenerateTestButton";
import {bindActionCreators} from "redux";
import {connect} from "react-redux"
import {
    editorDeleteTemplate,
    editorDownloadTemplate,
    editorIndexTemplates,
    editorTestTemplate,
    editorUploadTemplate
} from "../../../../redux-actions/editor";

import _ from "lodash-es"

const DocumentsTemplates = ({editorUploadTemplate, editorDeleteTemplate, editorIndexTemplates, editorTestTemplate, editorDownloadTemplate, templatesInfo}) => {

    useEffect(() => {
        editorIndexTemplates();
    }, [editorIndexTemplates]);


    return (<>
        <Row>
            <Col>
                <HowTo/>
            </Col>
        </Row>
        <Row>
            <Col>
                <Typography>
                    <Typography.Title level={4}>Templates</Typography.Title>
                </Typography>
            </Col>
        </Row>
        <Row gutter={{xs: 10, lg: 50}}>

            <Col xs={24} md={8}>
                <TemplateUpload
                    title="Acceptance letter"
                    fileInfo={templatesInfo["acceptance-letter"]}
                    uploadAction={editorUploadTemplate}
                    deleteAction={editorDeleteTemplate}
                    template="acceptance-letter"
                    downloadAction={editorDownloadTemplate}
                />
                <GenerateTestButton
                    action={() => editorTestTemplate('acceptance-letter')}
                    disabled={!_.get(templatesInfo["acceptance-letter"], 'exist')}
                />
            </Col>
            <Col xs={24} md={8}>
                <TemplateUpload
                    title="Invitation letter"
                    fileInfo={templatesInfo["invitation-letter"]}
                    uploadAction={editorUploadTemplate}
                    deleteAction={editorDeleteTemplate}
                    downloadAction={editorDownloadTemplate}
                    template="invitation-letter"
                />
                <GenerateTestButton
                    action={() => editorTestTemplate('invitation-letter')}
                    disabled={!_.get(templatesInfo["invitation-letter"], 'exist')}
                />
            </Col>
            <Col xs={24} md={8}>
                <TemplateUpload
                    title="Certificate of attendance"
                    fileInfo={templatesInfo["certificate"]}
                    uploadAction={editorUploadTemplate}
                    deleteAction={editorDeleteTemplate}
                    downloadAction={editorDownloadTemplate}
                    template="certificate"
                />
                <GenerateTestButton
                    action={() => editorTestTemplate('certificate')}
                    disabled={!_.get(templatesInfo["certificate"], 'exist')}
                />
            </Col>
        </Row>
    </>)
};


const mapStateToProps = (state, ownProps) => ({
    templatesInfo: state.editor.templates
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        editorUploadTemplate,
        editorDeleteTemplate,
        editorIndexTemplates,
        editorDownloadTemplate,
        editorTestTemplate
    },
    dispatch,
)


export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTemplates)
