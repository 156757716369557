import React from 'react';
import {Button, Form, Icon, message, Modal, Select, Upload} from 'antd';
import {withRouter} from "react-router-dom";
import config from "../config";
import styles from './Documents.module.scss'
import PassportForm from "./PassportForm";
import cd from "content-disposition";
import download from "downloadjs";

const {Option} = Select;

class DocumentsForm extends React.Component {

    state = {
        fileList: [],
        templateName: '',
        documentLoading: false,
        passportDialogVisible: false
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.user !== this.props.user) {
            if (this.props.user.documents) {
                const fileList = this.props.user.documents.map(doc => ({
                    uid: doc,
                    name: doc,
                    url: `${config.apiUrl}/users/${this.props.user.id}/documents/${doc}`,
                    status: 'done'
                }));
                this.setState({fileList: fileList})
            }
        }
    }

    onFileRemove = (file) => {
        this.props.removeAction(file.url)
    };

    onUploadChange = (info) => {
        let fileList = info.fileList;

        // Case: removing file
        if (fileList && fileList.length === 0) {
            this.setState({fileList});
            return;
        }

        // Case: url and id
        fileList = fileList.map((file, index) => {
            if (file && file.response) {
                // Response contains whole user object
                file.url = `${config.apiUrl}/users/${this.props.user.id}/documents/${file.name}`;
                file.uid = file.name
            }

            return file;
        });

        // Remove wrong files
        fileList = fileList.filter((file, index) => {
            return file.status !== 'error';
        });

        this.setState({fileList});

    };

    onGeneratorSelectionChange = (value) => {
        this.setState({templateName: value});
    };

    downloadSelectedDocument = (data) => {
        this.props.generateDocument(this.state.templateName, data, this.props.user).then((response) => {
            const disposition = cd.parse(response.payload.headers['content-disposition']);
            download(response.payload.data, disposition.parameters.filename);
            this.setState({documentLoading: false});
            this.confirmUploadFromTemplate(this.state.templateName, this.props.user);
        }).catch((err) => {
            message.error('Wrong template');
            this.setState({documentLoading: false});
        })
    };

    generateDocument = () => {
        if (this.state.templateName === 'invitation-letter') {
            this.setState({passportDialogVisible: true});
        } else {
            this.setState({documentLoading: true});
            this.downloadSelectedDocument(null);
        }

    };

    render() {
        const {user, auth} = this.props;

        return (
            <div>
                <div className={styles.templatesWrapper}>
                    <div className={styles.templates}>
                        <Select
                            style={{width: 300}}
                            placeholder="Select a template"
                            onChange={this.onGeneratorSelectionChange}
                        >
                            <Option value="invitation-letter">Invitation letter</Option>
                            <Option value="acceptance-letter">Acceptance letter</Option>
                            <Option value="certificate">Certificate of attendance</Option>
                        </Select>

                        <Button style={{marginLeft: 10}} loading={this.state.documentLoading} shape="circle"
                                onClick={this.generateDocument} icon="printer"/>
                    </div>
                </div>

                <Upload.Dragger
                    name="files"
                    action={`${config.apiUrl}/users/${user.id}/documents/?access_token=${auth.token}`}
                    fileList={this.state.fileList}
                    defaultFileList={[...this.state.fileList]}
                    onChange={this.onUploadChange}
                    onRemove={this.onFileRemove}
                    multiple={true}
                    accept='.pdf'
                >

                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox"/>
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        The uploaded files will be available to the selected participant. The participant will receive
                        an e-mail notification of the file.
                    </p>

                </Upload.Dragger>


                <Modal
                    title={'Passport information'}
                    visible={this.state.passportDialogVisible}
                    onOk={this.passportDialogConfirmed}
                    onCancel={this.passportDialogCancel}
                >
                    <PassportForm ref={(ref) => this.passportFormRef = ref}/>
                </Modal>
            </div>
        );
    }


    passportDialogConfirmed = () => {
        this.setState({passportDialogVisible: false, documentLoading: true});
        this.passportFormRef.getForm().validateFields((err, values) => {
            if (err) return;
            this.downloadSelectedDocument(values)
        });

    };

    passportDialogCancel = () => {
        this.setState({passportDialogVisible: false})
    }

    confirmUploadFromTemplate = (template, user) => {
        Modal.confirm({
            title: 'Confirm',
            content: 'Please check the generated document and accept the content.',
            okText: 'Accept',
            cancelText: 'Decline',
            onOk: () => {this.props.acceptDocument(template, user)},
            onCancel: () => {this.props.declineDocument(template, user)}
        });
    }
}

DocumentsForm.propTypes = {};

DocumentsForm = Form.create()(DocumentsForm);
DocumentsForm = withRouter(DocumentsForm);
export default DocumentsForm
