import {API_GET_PUBLIC_SETTINGS,} from "../constants/action-types";


export const getPublicSettings = () => {
    return {
        type: API_GET_PUBLIC_SETTINGS,
        payload: {
            request: {
                url: `/settings/`,
                method: 'get',
            }
        },
    }
}
