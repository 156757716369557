import React, {useRef, useState} from 'react';
import {Button, Col, message, Modal, Row} from "antd";
import _ from 'lodash-es'
import PropTypes from "prop-types";
import Details from "./Details";
import Invoice from "./Invoice";
import Survey from "./Survey";
import CredentialsForm from "../../forms/user/CredentialsForm";
import AddressForm from "../../forms/user/AddressForm";
import SurveyForm from "../../forms/user/SurveyForm";
import Actions from "../../forms/user/Actions";
import {If} from "babel-plugin-jsx-control-statements/macro"

const EditButton = ({allowEdit, name, showForm}) => {
    return (
        allowEdit &&
        <Row>
            <Col style={{marginTop: 20}}>
                <Button onClick={() => showForm(name)}>Edit</Button>
            </Col>
        </Row>
    )
};


const UserView = ({allowEdit, user, userRemove, userCredentialsUpdate, userAddressUpdate, userSurveyUpdate, showActions}) =>{
    let activeFormRef = useRef(null);

    const [activeform, setActiveForm] = useState({
        form: null,
        action: null
    });

    const handleSubmitForm = (e) => {
        activeFormRef.current.getForm().validateFields((err, values) => {
            const filteredValues = _.pickBy(values, v => v !== null && v !== undefined && v !== '');
            console.log(filteredValues);
            if (err) {
                console.error(err);
                return
            }

            activeform.action(filteredValues, user.id)
                .then((response) => message.info("Participant information has been updated."))
                .catch((error) => message.error("participant information has NOT been updated") && console.error(error));
            hideForms();
        });
    };

    const showForm = (formName) => {
        const forms = {
            credentials: userCredentialsUpdate,
            address: userAddressUpdate,
            survey: userSurveyUpdate,
        };

        setActiveForm({
            form: formName,
            action: forms[formName]
        })

    };


    const hideForms = () => {
        setActiveForm({
            form: null,
            action: null
        })
    };


        return (
            <div>

                <Row gutter={50}>
                    <Col xs={24} lg={12}>
                        <Details user={user}/>
                        <EditButton allowEdit={allowEdit} showForm={() => showForm('credentials')}/>

                    </Col>
                    <Col xs={24} lg={12}>
                        <Invoice user={user}/>
                        <EditButton allowEdit={allowEdit} showForm={() => showForm('address')}/>
                    </Col>
                </Row>
                <Row gutter={50}>
                    <Col xs={24} lg={12}>
                        <Survey user={user}/>
                        <EditButton allowEdit={allowEdit} showForm={() => showForm('survey')}/>
                    </Col>

                    <If condition={showActions}>
                    <Col xs={24} lg={12}>
                        <Actions userRemove={userRemove} user={user}/>
                    </Col>
                    </If>
                </Row>


                <Modal
                    title="Participant details"
                    visible={activeform.form === 'credentials'}
                    onOk={handleSubmitForm}
                    onCancel={() => hideForms()}
                    destroyOnClose={true}
                >
                    <CredentialsForm
                        user={user}
                        colOffset={0}
                        ref={activeFormRef}
                    />
                </Modal>


                <Modal
                    title="Mailing address"
                    visible={activeform.form === 'address'}
                    onOk={handleSubmitForm}
                    onCancel={() => hideForms()}
                    destroyOnClose={true}
                >
                    <AddressForm
                        user={user}
                        colOffset={0}
                        ref={activeFormRef}
                    />
                </Modal>

                <Modal
                    title="Survey"
                    visible={activeform.form === 'survey'}
                    onOk={handleSubmitForm}
                    onCancel={() => hideForms()}
                    destroyOnClose={true}
                >
                    <SurveyForm
                        user={user}
                        colOffset={0}
                        ref={activeFormRef}
                    />
                </Modal>

            </div>
        );
    };


UserView.propTypes = {
    user: PropTypes.object,
    allowEdit: PropTypes.bool,
};

export default UserView
