import React, {useState} from "react";
import {Button} from "antd";
import cd from "content-disposition";
import download from "downloadjs";

export const GenerateTestButton = ({disabled, action}) => {

    const [loading, setLoading] = useState(false);

    const doAction = () => {
        setLoading(true);
        setTimeout(
            action().then(response => {
                const disposition = cd.parse(response.payload.headers['content-disposition'])
                download(response.payload.data, disposition.parameters.filename);
                setLoading(false);
            }), 0);
    };

    return(
        <Button type="primary" loading={loading} icon="file-pdf" block size="large" disabled={disabled} onClick={doAction}>
            Test
        </Button>
    )
}
