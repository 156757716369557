import {Icon} from "antd";
import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components"

const RedIcon = styled(Icon)`
  color: #f5222d
`

const GreenIcon = styled(Icon)`
  color: #389e0d
`

export const Paid = ({value,  onClick}) => {
    if(!value)
        return (
            <>
                <RedIcon type="close-circle" /> <Link onClick={onClick} to="#"> Not Paid </Link>
            </>
 );

    return  (
        <>
            <GreenIcon type="check-circle" /> <Link onClick={onClick} to="#"> Paid </Link>
        </>
    )
}
