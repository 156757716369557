import React, {Component} from 'react';
import {Button, Form, Icon, Input, message} from 'antd';
import styles from './Login.module.scss'
import {Link, withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import {userLogin} from "../redux-actions/auth";
import querystring from "querystring"

const FormItem = Form.Item;

class LoginForm extends Component {

    state = {
        loading: false
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({loading: true});
                this.props.userLogin({
                    username: values.email,
                    password: values.password
                })
                    .then(() => {
                        const params = querystring.parse(this.props.location.search.substring(1))   // substring removes first '?'
                        this.setState({loading: false}, () => this.props.history.push(params.redirect ? params.redirect : '/profile'));
                    })
                    .catch((response) => {
                        this.setState({loading: false}, () => message.error('Wrong email or password!'));
                    })
            }
        });
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} >
                <FormItem>
                    {getFieldDecorator('email', {
                        rules: [{required: true, message: 'Please input your email!'}],
                    })(
                        <Input prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>} placeholder="Email"/>
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('password', {
                        rules: [{required: true, message: 'Please input your Password!'}],
                    })(
                        <Input prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>} type="password"
                               placeholder="Password"/>
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" htmlType="submit" className={styles.loginFormButton} loading={this.state.loading}>
                        Log in
                    </Button>
                    Or <Link to="/register">register now!</Link>
                    <Link className={styles.loginFormForgot} to="/forgot-password">Forgot password</Link>
                </FormItem>

            </Form>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {}
}
const mapDispatchToProps = dispatch => bindActionCreators(
    {
        userLogin,
    },
    dispatch,
)

LoginForm = Form.create()(LoginForm)
LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginForm)
LoginForm = withRouter(LoginForm)
export default LoginForm;
