import React from 'react';
import {Col, Form, Input, Row, Select} from "antd";
import {getData} from "country-list";
import PropTypes from 'prop-types'
import _ from "lodash-es";

const countriesOptionsList = getData().map(c => <Select.Option
    key={c['code']}
    value={c['code']}>
    {c['name']}
</Select.Option>);

const AddressForm = ({user, form, colOffset}) => {


    const {getFieldDecorator} = form;

    return (
        <Row style={{marginTop: 20}}>
            <Col offset={colOffset} xs={24 - 2 * colOffset}>
                <Form layout="vertical">
                    <Row>
                        <Col xs={14}>

                            <Form.Item
                                label={(
                                    <span>Address</span>
                                )}
                            >
                                {getFieldDecorator('address', {
                                    initialValue: _.get(user, 'address', '')
                                })(
                                    <Input.TextArea rows={6}/>
                                )}
                            </Form.Item>
                        </Col>


                        <Col xs={9} offset={1}>

                            <Form.Item

                                label={(<span>Country</span>)}
                            >
                                {getFieldDecorator('country', {
                                    initialValue: _.get(user, 'country', '')
                                })(
                                    <Select
                                        style={{width: "100%"}}
                                        showSearch
                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        placeholder="Please select a country"

                                    >
                                        {countriesOptionsList}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    );
};

AddressForm.propTypes = {
    user: PropTypes.object,
    colOffset: PropTypes.number,
    country: PropTypes.string,
    formContent: PropTypes.object,
};

AddressForm.defaultProps = {
    colOffset: 2
}


export default Form.create({})(AddressForm);
