import React from "react"
import {Badge, Col, Collapse, List, Row} from "antd/lib/index";
import {conference} from "../../../constants/strings";
import {Link} from "react-router-dom";

const Panel = Collapse.Panel;

function EventsStatsPanel(props) {
    if(!props.data) return <></>

    // generate array like: [{eventId: '', count: 0}]
    let listData = Object.keys(props.data).map(k => ({eventId: k, count: props.data[k]}));

    return (
        <Collapse>
        <Panel header="Events">
            <Row>
                <Col xs={24} >
                    <div>
                        <List
                            size="small"
                            dataSource={listData}
                            renderItem={item => (
                                <List.Item key={item.eventId}>
                                    <div style={{width: '90%', display: 'inline-block'}}>
                                        <Link to={`/editor/users?${item.eventId}=1`}>{conference.events[item.eventId]}</Link>
                                    </div>
                                    <div style={{display: 'inline-block'}}>
                                    <Badge overflowCount={1000} showZero style={{ backgroundColor: '#108ee9' }}  count={item.count}/>
                                    </div>
                                </List.Item>
                            )}
                        >
                        </List>
                    </div>
                </Col>
            </Row>
        </Panel>
        </Collapse>
    )
}

export default EventsStatsPanel;
