import React, {Component} from 'react';
import {Button, Form, Icon, Input, message} from 'antd';
import styles from './Login.module.scss'
import {bindActionCreators} from "redux";
import {requestNewPassword} from "../redux-actions/auth";
import connect from "react-redux/es/connect/connect";
import {Link} from "react-router-dom";

const FormItem = Form.Item;

class RequestNewPassword extends Component {

    state = {
        btnDisabled: false
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.requestNewPassword(values.email)
                    .then(() => {
                        this.setState({btnDisabled: true})
                        message.success('Password reset request has been sent. Please check your email.');
                    })
                    .catch((response) => {
                        message.error('Provided email was not found in our database');
                    })
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} className={styles.loginForm}>
                <h2>Forgot Password</h2>
                <span>Enter email to reset your password</span>
                <FormItem>
                    {getFieldDecorator('email', {
                        rules: [
                            { required: true, message: 'Please input your email!' },
                            { type: 'email', message: 'The input is not valid E-mail!'}
                            ],
                    })(
                        <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                    )}
                </FormItem>
                <FormItem>
                    <Button type="primary" disabled={this.state.btnDisabled} htmlType="submit" className={styles.loginFormButton}>
                        Reset my password
                    </Button>
                    <Link to="/">Back to login page</Link>
                </FormItem>
            </Form>
        );
    }
}

const mapStateToProps = (state, ownProps) => { return {} }
const mapDispatchToProps = dispatch => bindActionCreators(
    {
        requestNewPassword,
    },
    dispatch,
)

RequestNewPassword = Form.create()(RequestNewPassword)
RequestNewPassword = connect(mapStateToProps, mapDispatchToProps)(RequestNewPassword)

export default RequestNewPassword;
