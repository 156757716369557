import React, {Component} from 'react';
import {Button, Form, Input, message} from 'antd';
import styles from './Login.module.scss'
import {bindActionCreators} from "redux";
import {resetPassword} from "../redux-actions/auth";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";

const FormItem = Form.Item;

class ResetPassword extends Component {
    state = {
        confirmDirty: false,
        btnDisabled: false
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const {form, match, resetPassword} = this.props

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({btnDisabled: true})
                const token = match.params.token
                const password = values.password
                resetPassword(token, password).then(() => {
                    message.loading('Password has been change. You can proceed to the login page...', 1.0)
                        .then(() => this.props.history.push('/'))
                        })
                .catch((response) => {
                    message.error('Could not change a password.');
                })
            }
        });
    }

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({confirmDirty: this.state.confirmDirty || !!value});
    }

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter are inconsistent!');
        } else {
            callback();
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        callback();
    }


    render() {
        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 10},
                sm: {span: 10},
            },
            wrapperCol: {
                xs: {span: 14},
                sm: {span: 14},
            },
        };

        return (
            <Form onSubmit={this.handleSubmit} >
                <h2>Reset your password</h2>
                <span>Please enter your new password below to reset</span>
                <FormItem
                    {...formItemLayout}
                    label="Password"
                    style={{marginTop: 20}}
                >
                    {getFieldDecorator('password', {
                        rules: [{
                            required: true, message: 'Please input your password!',
                        }, {
                            validator: this.validateToNextPassword,
                        },
                        {
                            min: 6, message: 'Password should have at least 6 characters'
                        }
                        ],
                    })(
                        <Input type="password"/>
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="Confirm Password"
                >
                    {getFieldDecorator('confirm', {
                        rules: [{
                            required: true, message: 'Please confirm your password!',
                        }, {
                            validator: this.compareToFirstPassword,
                        }],
                    })(
                        <Input type="password" onBlur={this.handleConfirmBlur}/>
                    )}
                </FormItem>
                <FormItem>
                    <Button disabled={this.state.btnDisabled} type="primary" htmlType="submit" className={styles.loginFormButton}>
                        Change password
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

const mapStateToProps = (state, ownProps) => { return {} }
const mapDispatchToProps = dispatch => bindActionCreators(
    {
        resetPassword,
    },
    dispatch,
)

ResetPassword = Form.create()(ResetPassword)
ResetPassword = withRouter(ResetPassword)
ResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPassword)

export default ResetPassword;
