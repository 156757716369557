import {Tag} from "antd";
import React from "react";
import _ from "lodash-es";

function ColorTag(props) {
    const {data} = props
    if(_.isBoolean(data) && data)
        return <Tag color="blue">YES</Tag>
    else if(_.isBoolean(data) && !data)
        return <Tag color="red">NO</Tag>

    return <Tag color="grey">N/A</Tag>
}

export default ColorTag