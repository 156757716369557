import React, {PureComponent} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Registration from "./pages/Registration";
import AbstractsList from "./pages/author/AbstractsList";
import AbstractPage from "./pages/author/AbstractPage"
import AbstractSubmission from "./pages/author/AbstractSubmission";
import ReviewsList from "./pages/reviewer/ReviewsList";
import Review from "./pages/reviewer/Review";
import NotFound from "./pages/NotFound";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import {ROLE_AUTHOR, ROLE_EDITOR, ROLE_REVIEWER} from "./constants/strings";
import AdminAbstractsList from "./pages/editor/AdminAbstractsList";
import AdminAbstractsView from './pages/editor/AdminAbstract'
import AdminUsers from "./pages/editor/AdminUsersList";
import AdminUserView from "./pages/editor/AdminUser";
import ProfilePage from "./pages/author/ProfilePage";
import FeePage from "./pages/author/FeePage";
import DocumentsPage from "./pages/author/Documents";
// import AbstractDownload from "./pages/author/AbstractDownload";
import jwtDecode from 'jwt-decode'
import AdminDashboard from "./pages/editor/AdminDashboard";
import AdminSettings from "./pages/editor/AdminSettings";

const AuthRoute = ({auth, roles, component, path, location, ...rest}) => {
    // If role is not required
    if(!roles)
        return <Route path={path} component={component}/>

    // If user is authenticated
    if (auth.token){
        // if main site -> redirect to profile
        if (rest.path === "/")
            return <Redirect to='/profile'/>


        // checks permission
        const tokenData = jwtDecode(auth.token);
        const canAccess = roles.includes(tokenData.role)
        if(canAccess)
            return <Route path={path} component={component}/>
    }

    // No authenticated
    return <Redirect to={`/?redirect=${location.pathname}`}/>

}


class Routing extends PureComponent {
    // componentDidMount() {
    //     const { history } = this.props;
    //
    //     window.addEventListener("popstate", () => {
    //         history.go(1);
    //     });
    // }

    render() {
        const {auth, location} = this.props

        const abstractAccess = [ROLE_AUTHOR, ROLE_REVIEWER, ROLE_EDITOR]
        const reviewsAccess = [ROLE_REVIEWER, ROLE_EDITOR]
        const adminAccess = [ROLE_EDITOR]
        return (
            <Switch>
                <AuthRoute auth={auth} path="/" exact component={Login} />
                <AuthRoute auth={auth} path="/forgot-password" component={ForgotPassword} />
                <AuthRoute auth={auth} path="/reset-password/:token" component={ResetPassword} />
                <AuthRoute auth={auth} path="/register" component={Registration} />
                <AuthRoute auth={auth} roles={abstractAccess} location={location} path="/fee" exact component={FeePage}/>
                <AuthRoute auth={auth} roles={abstractAccess} location={location} path="/documents" exact component={DocumentsPage}/>
                <AuthRoute auth={auth} roles={abstractAccess} location={location} path="/profile" exact component={ProfilePage}/>
                <AuthRoute auth={auth} roles={abstractAccess} location={location} path="/abstracts" exact component={AbstractsList} />
                {/*<AuthRoute auth={auth} roles={abstractAccess} location={location} path="/abstracts/download/:id" exact component={AbstractDownload}/>*/}
                <AuthRoute auth={auth} roles={abstractAccess} location={location} path="/abstracts/add" exact component={() => <AbstractSubmission type="submit"/>} />
                <AuthRoute auth={auth} roles={abstractAccess} location={location} path="/abstracts/:id" exact component={AbstractPage} />
                <AuthRoute auth={auth} roles={abstractAccess} location={location} path="/abstracts/:id/edit" component={() => <AbstractSubmission type="edit"/>}/>
                <AuthRoute auth={auth} roles={abstractAccess} location={location} path="/abstracts/:id/resubmit" component={() => <AbstractSubmission type="resubmit"/>}/>
                <AuthRoute auth={auth} roles={reviewsAccess} location={location} path="/reviews" exact component={ReviewsList}/>
                <AuthRoute auth={auth} roles={reviewsAccess} location={location} path="/reviews/:id" exact component={Review} />
                <AuthRoute auth={auth} roles={adminAccess} location={location} path="/editor/abstracts" exact component={AdminAbstractsList}/>
                <AuthRoute auth={auth} roles={adminAccess} location={location} path="/editor/abstracts/:id" component={AdminAbstractsView}/>
                <AuthRoute auth={auth} roles={adminAccess} location={location} path="/editor/users" exact component={AdminUsers}/>
                <AuthRoute auth={auth} roles={adminAccess} location={location} path="/editor/dashboard" exact component={AdminDashboard}/>
                <AuthRoute auth={auth} roles={adminAccess} location={location} path="/editor/users/:id/:tab?"  component={AdminUserView}/>
                <AuthRoute auth={auth} roles={adminAccess} location={location} path="/editor/settings"  component={AdminSettings}/>
                <Route component={NotFound}/>
            </Switch>
        );
    }
}

Routing.propTypes = {};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth
    }
}
const mapDispatchToProps = dispatch => bindActionCreators(
    {},
    dispatch,
)


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routing));
