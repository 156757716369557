import React from "react"
import {Col, Collapse, List, Row} from "antd/lib/index";
import {getName} from "country-list";
import FlagIcon from '../../../lists/Components/FlagIcon'
import {Link} from "react-router-dom";

function UsersStatsPanel(props) {

    if(!props.data) return <></>

    // generate array like: [{country: '', val: 0}]
    let listData = Object.keys(props.data).map(k => ({country: k, val: props.data[k]}));
    // sum participants and add to the list
    const total = Object.values(props.data).reduce((a, b) => { return a + b; }, 0);

    return (
        <Collapse>
            <Collapse.Panel header="Participants">
                <Row>
                    <Col xs={24}>
                        <div style={{height: '500px', overflowY: 'auto'}}>
                            <List
                                size="small"
                                dataSource={listData}
                                renderItem={item => (
                                    <List.Item key={item.country}>
                                    <span style={{width: '80%'}}>
                                    <FlagIcon code={item.country.toLowerCase()}/> <Link to={`/editor/users?country=${item.country}`}>{getName(item.country)}</Link>
                                    </span>
                                    <span>{item.val}</span>
                                    </List.Item>
                                )}
                            >
                                <List.Item>
                                        <span style={{width: '80%'}}>
                                             <strong>TOTAL</strong>
                                        </span>
                                    <span><strong>{total}</strong></span>
                                </List.Item>
                            </List>
                        </div>
                    </Col>
                </Row>
            </Collapse.Panel>
        </Collapse>
    )
}

export default UsersStatsPanel;
