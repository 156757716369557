import React from 'react'
import {Button, Col, Divider, Popconfirm, Row} from "antd";
import {useHistory} from "react-router-dom";

const Actions = ({userRemove, user}) => {
    let history = useHistory();

    const removeParticipant = () => {
        userRemove(user).then((response) => {
            history.push({
                pathname: `/editor/users/`
            });
        })
    };

    return (
        <Row type='flex' justify='center'>
            <Divider>Actions</Divider>
            <Col span={8}>
                <Popconfirm placement="bottom" title='Are you sure to delete this participant?' onConfirm={removeParticipant} okText="Yes" cancelText="No">
                    <Button block type="danger" icon='delete'>Delete account</Button>
                </Popconfirm>
            </Col>
        </Row>
    )
};

export default Actions
