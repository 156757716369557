import React, {useEffect, useRef, useState} from 'react';
import BasicLayout from "../../views/MainLayout";
import AbstractView from "../../fragments/AbstractView";
import {bindActionCreators} from "redux";
import {
    editorAssignReviewer,
    editorDeleteAbstract,
    editorGetAbstractById,
    editorGetReviewers
} from "../../redux-actions/editor";
import {connect} from "react-redux";
import AssignReviewerForm from "../../forms/AssignReviewer";
import ReviewView from "../../fragments/ReviewView";
import _ from "lodash-es";
import {useHistory} from 'react-router-dom'
import {Button, message, Modal, Popconfirm, Tooltip} from "antd";
import {downloadAbstract} from "../../redux-actions/author";

const ExtraButtons = ({editAction, deleteAction, assignAction}) => {
    return (
        <>
            <Tooltip placement="topLeft" title="Assign reviewer">
                <Button style={{margin: 5}} shape="circle" icon="solution" onClick={assignAction}/>
            </Tooltip>
            <Tooltip placement="topLeft" title="Edit">
                <Button style={{margin: 5}} shape="circle" icon="edit" onClick={editAction}/>
            </Tooltip>
            <Popconfirm
                title="Are you sure to delete this abstract?"
                onConfirm={deleteAction}
                okText="Yes"
                cancelText="No">
                <Button style={{margin: 5}} shape="circle" icon="delete"/>
            </Popconfirm>

        </>
    )
}

const AdminAbstract = ({getAbstractById, match: {params}, abstract, reviewers, sessions, getReviewers, assignReviewer, deleteAbstract, downloadAbstract}) => {
    let history = useHistory();
    let reviewerFormRef = useRef(null);

    const [assignVisible, setAssignVisible] = useState(false);

    useEffect(() => {
        const id = params.id;
        getAbstractById(id)
        getReviewers();
    }, [getAbstractById, getReviewers, params.id])


    const cardStyle = {marginTop: 20}

    const handleAssignReviewer = () => {
        reviewerFormRef.current.getForm().validateFields((err, values) => {
            if (err) return;
            assignReviewer(abstract.id, values.reviewer).then((response) => {
                message.success('Reviewer has been assigned');
            }).catch((error) => {
                message.error('Reviewer has not been assigned due to error');
            });
            setAssignVisible(false);
        })


        // this.props.form.validateFields((err, values) => {
        //     if (!err) {
        //         const abstract = this.props.abstract;
        //         // console.log(`Reviewer: ${values.reviewer} assign to abstract: ${abstractId} `)
        //         this.props.assign(abstract.id, values.reviewer).then((response) => {
        //             message.success('Reviewer has been assigned');
        //         }).catch((error) => {
        //             message.error('Reviewer has not been assigned due to error');
        //         })
        //     }
        // });
    }

    return (
        <BasicLayout>
            <AbstractView
                abstract={abstract}
                showSessionOrganizers
                allowEdit={true}
                sessionTitle={_.get(sessions.find(s => s.acronym === abstract.session), 'title', '')}
                downloadAbstract={downloadAbstract}
                extraButtons={<ExtraButtons
                    editAction={() => history.push(`/abstracts/${abstract.id}/edit`)}
                    deleteAction={() => {
                        deleteAbstract(abstract.id);
                        history.push(`/editor/abstracts/`);
                    }}
                    assignAction={() => setAssignVisible(true)}
                />}
            />
            <ReviewView style={cardStyle} review={abstract.review}/>

            <Modal
                title={`Assign reviewer`}
                visible={assignVisible}
                onOk={handleAssignReviewer}
                onCancel={() => setAssignVisible(false)}
            >
                <AssignReviewerForm ref={reviewerFormRef} abstract={abstract} reviewers={reviewers}/>
            </Modal>


        </BasicLayout>
    )

}


const mapStateToProps = (state, ownProps) => ({
    abstract: state.editor.abstract,
    reviewers: state.editor.reviewers,
    sessions: state.settings.sessions,
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        deleteAbstract: editorDeleteAbstract,
        getAbstractById: editorGetAbstractById,
        getReviewers: editorGetReviewers,
        assignReviewer: editorAssignReviewer,
        downloadAbstract: downloadAbstract
    },
    dispatch,
)


export default connect(mapStateToProps, mapDispatchToProps)(AdminAbstract)
