import React from 'react';
import {Button, Card} from 'antd';
import PropTypes from 'prop-types'
import {withRouter} from "react-router-dom";
import ReviewResponse from "./ReviewResponse";


class Actions extends React.Component {

    resubmit = () => {
        const abstractId = this.props.abstract.id
        this.props.history.push(`/abstracts/${abstractId}/resubmit`)
    }

    render() {
        return (
            <div>
                <Card title="Actions" style={{...this.props.style}}>
                  <Button type="primary" icon="check" onClick={this.resubmit} htmlType="button">Correct and resubmit</Button>
                </Card>
            </div>
        );
    }
}

ReviewResponse.propTypes = {
    abstract: PropTypes.object,
}


Actions = withRouter(Actions)

export default Actions

