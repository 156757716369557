import {
    API_REVIEWER_ADD_REVIEW,
    API_REVIEWER_GET_ALL_REVIEWS,
    API_REVIEWER_GET_REVIEW_BY_ID
} from "../constants/action-types";

const initialState = {
    reviews: [],
    review: {}
};

const reviewerReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${API_REVIEWER_GET_ALL_REVIEWS}_SUCCESS`:
            return {
                ...state, reviews: action.payload.data
            }
        case `${API_REVIEWER_GET_REVIEW_BY_ID}_SUCCESS`:
            return {
                ...state, review: action.payload.data
            }
        case `${API_REVIEWER_ADD_REVIEW}_SUCCESS`:
            return {
                ...state
            }
        default:
            return state

    }
}

export default reviewerReducer;
