import {Tag} from "antd";
import React from "react";
import styled from "styled-components"
import {Link} from "react-router-dom";

const UserTag = styled(Tag)`
  width: 75px;
  text-align: center;
`

export const UserRole = ({role, onClick}) => {
    switch(role){
        case 'author':
            return <Link onClick={onClick} to="#"><UserTag color="blue">Author</UserTag></Link>
        case 'reviewer':
            return <Link onClick={onClick} to="#"><UserTag color="purple">Reviewer</UserTag></Link>
        case 'editor':
            return <Link onClick={onClick} to="#"><UserTag color="red">Editor</UserTag></Link>
        default:
            return <></>
    }
}
