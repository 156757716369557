import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Form, Icon, Input, Row, Select} from 'antd';
import {withRouter} from "react-router-dom";
import config from "../../config";
import Author from "./Author";
import _ from "lodash-es";
import {If} from "babel-plugin-jsx-control-statements/macro";
import {AbstractUpload} from "./AbstractUpload";
import notAllowAllCapital from "../../validators/notAllowAllCapital";

const {abstractTemplate} = config;
const FormItem = Form.Item;

let tmpAuthorId = 1;

const AbstractSubmission = ({form, abstract, sessions, keywords, keywordsSearch, type, onSubmit}) => {

    const {getFieldDecorator, getFieldValue} = form;
    const [showKeywords, setShowKeywords] = useState(false);
    const [speaker, setSpeaker] = useState(0);

    useEffect(() => {
        if(abstract.authors && abstract.authors.length > 0) {
            form.setFieldsValue({
                // authors: abstract.authors,
                keys: type === 'submit' ? [0] : Array.from(abstract.authors.keys()),
                file: type === 'submit' ? [] : [{
                    uid: abstract.file,
                    name: `Abstract_${abstract.authors[0].lastName}_${abstract.authors[0].firstName}`,
                    url: `${config.apiUrl}/abstracts/${abstract.id}/download`,
                    status: 'done'
                }]
            });

            setSpeaker(abstract.presenter);
        }
        setSpeaker(0);
        // eslint-disable-next-line
    }, [abstract]);



    const InfoMessage = () => {
        return (
            <div>
                <ul>
                    <li>Please use <a
                        href={abstractTemplate}>this template</a> to prepare your abstract.
                    </li>
                    <li>Complete the form below with <strong>exactly</strong> the same data as the abstract.</li>
                    <li>Abstracts shall be submitted as MS-Word (*.doc / *.docx) or PDF (Portable Document Format)
                        file.
                    </li>
                    <li>Acceptance or rejection letters and all future correspondence will be emailed to the <strong>Primary
                        Author</strong>.
                    </li>
                </ul>
            </div>
        )
    };

    const handleKeywordsSelect = (value, option) => {
        setShowKeywords(false);
    };

    const handleKeywordsSearch = (value) => {
        value = value.trim();        // prevents spaces before
        if (value.length <= 2) {
            setShowKeywords(false);
            return
        }

        keywordsSearch(value);
        setShowKeywords(true)
    };


    const addAuthor = (e) => {
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(tmpAuthorId++);

            form.setFieldsValue({
                keys: nextKeys,
                // speaker
            });
    };

    const removeAuthor = (index) => {
        let values = form.getFieldsValue();


        if (values.keys.length === 1) {
            return;
        }

        const authors = values.authors.filter((v, i) => i !== index);
        const keys = values.keys.filter((v, i) => i !== index);
        form.setFieldsValue({authors, keys});

        if(index <= speaker){
            setSpeaker(index === 0 ? 0 : speaker - 1);
        }
    };

    const moveDownAuthor = (index) => {
        let {authors} = form.getFieldsValue(['authors']);
        [authors[index], authors[index+1]] = [authors[index+1], authors[index]];
        form.setFieldsValue({authors});
    };

    const moveUpAuthor = (index) => {
        let {authors} = form.getFieldsValue(['authors']);
        [authors[index], authors[index-1]] = [authors[index-1], authors[index]];
        form.setFieldsValue({authors});
    };

    const handleSubmit = (e) => {
        form.getFieldDecorator('presenter', { initialValue: speaker});
        onSubmit(e)
    }

    const getTitleCaption = () => {
        switch(type) {
            case 'submit': return "Submit new abstract";
            case 'edit':
            case 'resubmit': return `Edit: '${_.truncate(abstract.title, {length: 150, omission: '...'}) || ''}'`;
            default: return '';
        }
    };

    const getSubmitButtonCaption = () => {
        switch(type) {
            case 'submit': return 'Submit';
            case 'edit': return 'Save';
            case 'resubmit': return 'Submit';
            default: return 'Save';
        }
    };

    getFieldDecorator('keys', {initialValue: [0]});
    const keys = getFieldValue('keys');


    return (
        <Card title={getTitleCaption()} style={{marginBottom: 20}}>
            {type === 'submit' && <Row>
                <Col xs={24}>
                    <Alert
                      style={{marginBottom: 20}}
                      showIcon
                      closable
                      message="Abstract submission rules & guidelines"
                      description={<InfoMessage/>}
                      type="info"
                    />

                </Col>
            </Row>
            }

            <Form onSubmit={handleSubmit} layout='vertical'>
                <p className="ant-form-item-required">Authors:</p>
                {

                    abstract.authors && keys.map((k, index) => <Author
                                            form={form}
                                            key={k}
                                            author={abstract.authors[index]}
                                            index={index}
                                            count={keys.length}
                                            onRemove={() => removeAuthor(index)}
                                            onMoveDown={() => moveDownAuthor(index)}
                                            onMoveUp={() => moveUpAuthor(index)}
                                            isSpeaker={index === speaker}
                                            onSetSpeaker={() => setSpeaker(index)}
                                        />)
                }

                <Row gutter={25}>
                    <Col xs={3}>
                        <FormItem>
                            <Button type="primary" onClick={addAuthor}>
                                <Icon type="plus"/> Add author
                            </Button>
                        </FormItem>
                    </Col>
                </Row>


                <Row>
                    <Col xs={24}>
                        <FormItem
                            label="Title"
                        >
                            {getFieldDecorator('title', {
                                rules: [{
                                    required: true, message: 'Title is required',
                                },
                                {
                                    validator: notAllowAllCapital
                                }
                                ],

                                initialValue: _.get(abstract, `title`, '')
                            })(
                                <Input/>
                            )}
                        </FormItem>
                    </Col>
                </Row>

                <Row gutter={25}>
                    <Col xs={24} lg={10}>
                        <FormItem
                            label="Session"
                        >
                            {getFieldDecorator('session', {
                                rules: [
                                    {required: true, message: 'Please select type of submission!'},
                                ],
                                initialValue: _.get(abstract, `session`, '')
                            })(
                                <Select>
                                    {sessions.map((value) => <Select.Option value={value.acronym}
                                                                                       key={value.acronym}>{value.title}</Select.Option>)}
                                </Select>
                            )}
                        </FormItem>
                    </Col>

                    <Col lg={14} xs={24}>
                        <FormItem
                            label="Keywords"
                        >
                            {getFieldDecorator('keywords', {
                                rules: [{
                                    required: true, message: 'Keywords are required',
                                }],
                                initialValue: _.get(abstract, `keywords`, [])
                            })(
                                <Select
                                    mode="tags"
                                    notFoundContent={null}
                                    tokenSeparators={[',']}
                                    placeholder="Comma separated list of keywords (at least 5)"
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    open={showKeywords}
                                    onSearch={handleKeywordsSearch}
                                    onSelect={handleKeywordsSelect}
                                    dropdownMatchSelectWidth={false}
                                >
                                    {keywords.map((keyword, i) => <Select.Option key={`${keyword}_${i}`}
                                                                                       value={keyword}>{keyword}</Select.Option>)}
                                </Select>,
                            )}
                        </FormItem>
                    </Col>
                </Row>

                <Row type='flex' justify='center'>
                    <Col xs={24}>
                        <AbstractUpload form={form} abstract={abstract}/>
                    </Col>
                </Row>

                <If condition={type === 'resubmit'}>
                    <Row>
                        <Col>
                            <FormItem
                                label="Comments"
                            >
                                {getFieldDecorator('comment', {})(
                                    <Input.TextArea placeholder="Message for the reviewer" rows={3}/>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                </If>
                <FormItem >
                    <Button type="primary" htmlType="submit" icon="right">{getSubmitButtonCaption()}</Button>
                </FormItem>
            </Form>
        </Card>
    );
};

export default withRouter(Form.create()(AbstractSubmission));

