import React from "react";
import {Tabs} from "antd";
import {TracksSettings} from "./Tracks";
import {MailingSettings} from "./Mailing";
import DocumentsTemplates from "./DocumentsTemplates";
import {NotificationsSettings} from "./Notifications";

const { TabPane } = Tabs;

export const Settings = (props) => {
    return(
            <Tabs animated={false}>
                <TabPane tab="Tracks" key="tracks" disabled={true}>
                    <TracksSettings/>
                </TabPane>
                <TabPane tab="Mailing" key="mailing" disabled={true}>
                    <MailingSettings/>
                </TabPane>
                <TabPane tab="Documents" key="documents" >
                    <DocumentsTemplates/>
                </TabPane>
                <TabPane tab="Notifications" key="notifications" disabled={true}>
                    <NotificationsSettings/>
                </TabPane>
            </Tabs>
    )
}
