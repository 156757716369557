import {
    API_AUTHOR_DELETE_ABSTRACT,
    API_AUTHOR_GET_ABSTRACT,
    API_AUTHOR_GET_ABSTRACTS,
    API_AUTHOR_GET_KEYWORDS,
    API_AUTHOR_SUBMIT_ABSTRACT,
    AUTHOR_ADD_FIRST_AUTHOR_TO_ABSTRACT,
    AUTHOR_CLEAR_STORED_ABSTRACT
} from "../constants/action-types";

const initialState = {
    abstracts: [],
    abstract: {},
    keywords: []
};
const authorReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${API_AUTHOR_SUBMIT_ABSTRACT}_SUCCESS`:
            return { ...state };
        case `${API_AUTHOR_GET_ABSTRACTS}_SUCCESS`:
            return {...state, abstracts: action.payload.data}
        case `${API_AUTHOR_GET_ABSTRACT}_SUCCESS`:
            return {...state, abstract: action.payload.data}
        case `${API_AUTHOR_DELETE_ABSTRACT}_SUCCESS`:
            return {...state}
        case AUTHOR_CLEAR_STORED_ABSTRACT:
            return {...state, abstract: {}}
        case AUTHOR_ADD_FIRST_AUTHOR_TO_ABSTRACT:
            let authors = [action.user]
            return {...state, abstract: {authors}}
        case `${API_AUTHOR_GET_KEYWORDS}_SUCCESS`:
            return {...state, keywords: action.payload.data}
        default:
            return state;
    }
}

export default authorReducer;
