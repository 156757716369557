import React, {Component} from 'react';
import BasicLayout from "../../views/MainLayout";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";

import {
    getCurrentUser,
    userAddressUpdate,
    userEventsUpdate,
    userFormEdit,
    userSurveyUpdate,
    userUpdate,
} from "../../redux-actions/auth";
import {message} from "antd";
import UserView from "../../fragments/UserView/UserView";

class ProfilePage extends Component {

    componentDidMount() {
        this.props.getCurrentUser();
    }


    handleSubmit = (e) => {
        e.preventDefault();
        if(this.props.action) {
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    this.props.action(values).then((response) => message.info("User information has been updated."))
                }
            });
        }
    }

    render() {
        const {user, userUpdate, userAddressUpdate, userSurveyUpdate} = this.props;

        return (
            <BasicLayout>
                <UserView
                    user={user}
                    allowEdit={true}
                    userCredentialsUpdate={userUpdate}
                    userAddressUpdate={userAddressUpdate}
                    userSurveyUpdate={userSurveyUpdate}
                    showActions={false}
                      />
            </BasicLayout>
        )
    }
}


const mapStateToProps = (state, ownProps) => ({
    user: state.auth.user,
    formContent : state.auth.userForm
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getCurrentUser,
        userUpdate,
        userSurveyUpdate,
        userAddressUpdate,
        userEventsUpdate,
        userFormEdit,
    },
    dispatch,
)

ProfilePage = connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
export default ProfilePage

