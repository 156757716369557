import React, {Component} from 'react';
import {Form, Select} from "antd";
import PropTypes from "prop-types";
import _ from 'lodash-es'

const FormItem = Form.Item;

class AssignReviewerForm extends Component {
    render() {
        const {getFieldDecorator} = this.props.form;

        const reviewersList = this.props.reviewers.map((reviewer) => <Select.Option value={reviewer.id} key={reviewer.id}>{`${reviewer.firstName} ${reviewer.lastName}`}</Select.Option>)

        return(
            <Form onSubmit={this.handleSubmit} >
                <FormItem
                >
                    {getFieldDecorator('reviewer', {
                        rules: [{
                            required: true, message: 'Select reviewer',
                        }],
                        initialValue: _.get(this.props.abstract, "review.reviewer.id")
                    })(
                        <Select>
                            {reviewersList}
                        </Select>
                    )}
                </FormItem>
            </Form>
        );
    }
}

AssignReviewerForm.propTypes = {
    abstract: PropTypes.object.isRequired,
};
AssignReviewerForm.defaultProps = {};

AssignReviewerForm = Form.create()(AssignReviewerForm)
export default AssignReviewerForm
