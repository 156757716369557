import React, {useCallback, useEffect} from 'react';
import BasicLayout from "../../views/MainLayout";
import AdminAbstracts from "../../lists/AdminAbstracts";
import {
    editorClearAbstracts,
    editorDownloadAllAbstracts,
    editorGetAbstracts, editorSetLastEditedAbstractId
} from "../../redux-actions/editor";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import qs from "query-string";
import {useLocation} from "react-router-dom";

const AdminAbstractsList = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();

    const abstracts = useSelector(state => state.editor.abstracts, shallowEqual);
    const sessions = useSelector(state => state.settings.sessions, shallowEqual);
    const lastId = useSelector(state => state.editor.lastEditedAbstractId, shallowEqual);

    const handleDownloadAllAbstracts = useCallback(
        () => dispatch(editorDownloadAllAbstracts()),
        [dispatch]
    );

    const handleSetLastId = useCallback(
        (abstractId) => dispatch(editorSetLastEditedAbstractId(abstractId)),
        [dispatch]
    );

    useEffect(() => {
        const filter = qs.parse(location.search)
        dispatch(editorGetAbstracts(filter))
    }, [dispatch, location])

    useEffect(() => {
        return () => {
            dispatch(editorClearAbstracts());
        }
    }, [dispatch]);

    return (
        <BasicLayout>
            <AdminAbstracts
                abstracts={abstracts}
                lastId={lastId}
                sessions={sessions}
                setLastId={handleSetLastId}
                downloadAllAbstracts={handleDownloadAllAbstracts}
            />
        </BasicLayout>
    );
};

export default AdminAbstractsList;
