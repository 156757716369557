import React, {PureComponent} from 'react';
import {Avatar, Col, Dropdown, Icon, Layout, Menu, Row} from 'antd';
import {withRouter} from "react-router-dom";
import AppMenu from "./AppMenu";
import styles from "./MainLayout.module.scss"
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {logout} from "../redux-actions/auth";
import config from "../config"

const {title, subTitle} = config
const {Header, Sider, Content, Footer} = Layout;

class MainLayout extends PureComponent {

    handleLogout = () => {
        this.props.logout()
        this.props.history.push('/')
    }

    handleNavToProfile = () => {
        this.props.history.push('/profile')
    }

    menu = (
        <Menu>
            <Menu.Item key="editUser" onClick={this.handleNavToProfile}><Icon type="edit"/> Edit profile
                information</Menu.Item>
            <Menu.Item key="logout" onClick={this.handleLogout}><Icon type="logout"/> Logout</Menu.Item>
        </Menu>
    );


    render() {
        return (
            <Layout className={styles.mainLayout}>
                <Header className={styles.header}>
                    <div className={styles.logo}>
                        <Icon className={styles.confIcon} type="solution"/> {title}
                    </div>

                    <div className={styles.avatarWrapper}>
                        <Dropdown overlay={this.menu} trigger={['hover']}>
                            <Avatar>
                                <Icon type="user" style={{fontSize: 16}}/>
                            </Avatar>
                        </Dropdown>
                    </div>
                </Header>
                <Content className={styles.uiWrapper}>
                    <Layout>
                        <Sider width={200}>
                            <AppMenu role={this.props.auth.user.role}/>
                        </Sider>
                        <Content className={styles.appContent}>
                            {this.props.children}
                        </Content>
                    </Layout>
                </Content>

                <Footer className={styles.footer}>
                    <Row>
                        <Col md={4} xs={24}><span style={{color: '#cdcdcd'}}>{<code>v{global.appVersion}</code>}</span></Col>
                        <Col md={16} xs={24} style={{textAlign: 'center'}}>{title} - {subTitle}</Col>
                        <Col md={4} xs={24} style={{textAlign: 'right'}}>Developed by <a
                            href="mailto:kbzowski@agh.edu.pl">Krzysztof Bzowski</a></Col>
                    </Row>
                </Footer>
            </Layout>
        );
    }
}

MainLayout.propTypes = {};

const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.auth
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
        logout
    }, dispatch
)


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout));

