import React from "react"
import {Col, Collapse, List, Row} from "antd/lib/index";
import _ from 'lodash-es'
import {Badge} from "antd";
import {Link} from "react-router-dom";

const Panel = Collapse.Panel;

function AbstractsStatsPanel(props) {

    if(!props.data) return <></>

    let total = 0;
    let listData = [];

    if (!_.isEmpty(props.data)) {
        listData = props.data
        // sum participants and add to the list
        total = listData.map(item => item.count).reduce((prev, next) => prev+next)
    }


    return (
        <Collapse>
        <Panel header="Abstracts">
            <Row>
                <Col xs={24} >
                    <div>
                        <List
                            size="small"
                            dataSource={listData}
                            renderItem={item => (
                                <List.Item key={item._id}>
                                    <div style={{width: '90%', display: 'inline-block'}}>
                                        <Link to={`/editor/abstracts?session=${item._id}`}>{_.get(props.sessions.find(s => s.acronym === item._id), 'title', <i>UNKNOWN SESSION</i>)}</Link>
                                    </div>
                                    <div style={{display: 'inline-block'}}>
                                        <Badge showZero style={{ backgroundColor: '#1eaf39' }}  count={item.count}/>
                                    </div>
                                </List.Item>
                            )}
                        >
                            <List.Item>
                                <span>
                                    <strong>TOTAL: {total}</strong>
                                </span>
                            </List.Item>
                        </List>
                    </div>
                </Col>
            </Row>
        </Panel>
        </Collapse>
    )
}

export default AbstractsStatsPanel;
