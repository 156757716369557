export const API_AUTHOR_SUBMIT_ABSTRACT = "API_AUTHOR_ADD_ABSTRACT";

export const LOGOUT = 'LOGOUT'
export const EDIT_USER_FORM = 'EDIT_USER_FORM'
export const RETRIEVE_AUTH_FROM_STORAGE = 'RETRIEVE_AUTH_FROM_STORAGE'
export const API_REGISTER_USER = "API_REGISTER_USER"
export const API_LOGIN_USER = "API_LOGIN_USER"
export const API_REQUEST_NEW_PASSWORD = "API_REQUEST_NEW_PASSWORD"
export const API_RESET_PASSWORD = "API_RESET_PASSWORD"
export const API_GET_USERINFO = "API_GET_USERINFO"
export const API_UPDATE_USER = "API_UPDATE_USER"
export const API_UPDATE_USER_SURVEY = 'API_UPDATE_USER_SURVEY'
export const API_UPDATE_USER_ADDRESS = 'API_UPDATE_USER_ADDRESS'
export const API_UPDATE_USER_EVENTS = 'API_UPDATE_USER_EVENTS'

export const API_EDITOR_GET_ALL_ABSTRACTS = 'API_EDITOR_GET_ALL_ABSTRACTS'
export const API_EDITOR_CLEAR_ABSTRACTS = 'API_EDITOR_CLEAR_ABSTRACTS'
export const API_EDITOR_GET_ABSTRACT = 'API_EDITOR_GET_ABSTRACT'
export const API_EDITOR_GET_REVIEWERS = 'API_EDITOR_GET_REVIEWERS'
export const API_EDITOR_ASSIGN_REVIEWER = 'API_EDITOR_ASSIGN_REVIEWER'
export const API_EDITOR_GET_USERS = 'API_EDITOR_GET_USERS'
export const API_EDITOR_CLEAR_USERS = 'API_EDITOR_CLEAR_USERS'
export const API_EDITOR_COUNT_USERS = 'API_EDITOR_COUNT_USERS'
export const API_EDITOR_DELETE_USER = 'API_EDITOR_DELETE_USER'
export const API_EDITOR_SET_USER_ROLE = 'API_EDITOR_SET_USER_ROLE'
export const API_EDITOR_GET_USER_FEE = 'API_EDITOR_GET_USER_FEE'
export const API_EDITOR_SET_USER_FEE = 'API_EDITOR_SET_USER_FEE'
export const API_EDITOR_DELETE_ABSTRACT = 'API_EDITOR_DELETE_ABSTRACT'
export const API_EDITOR_DELETE_DOCUMENT = 'API_EDITOR_DELETE_DOCUMENT'
export const API_EDITOR_EXPORT_PARTICIPANTS = 'API_EDITOR_EXPORT_PARTICIPANTS'
export const API_EDITOR_GET_USERS_BY_COUNTRY = 'API_EDITOR_GET_USERS_BY_COUNTRY'
export const API_EDITOR_GET_USERS_BY_EVENT = 'API_EDITOR_GET_USERS_BY_EVENT'
export const API_EDITOR_GET_ABSTRACTS_STATS = 'API_EDITOR_GET_ABSTRACTS_STATS'
export const EDITOR_CHANGE_USERS_LIST_PAGE = 'EDITOR_CHANGE_USERS_LIST_PAGE'
export const API_EDITOR_DOWNLOAD_ALL_ABSTRACTS = 'API_EDITOR_DOWNLOAD_ALL_ABSTRACTS'
export const API_EDITOR_UPLOAD_TEMPLATE = 'API_EDITOR_UPLOAD_TEMPLATE'
export const API_EDITOR_DELETE_TEMPLATE = 'API_EDITOR_DELETE_TEMPLATE'
export const API_EDITOR_INDEX_TEMPLATES = 'API_EDITOR_INDEX_TEMPLATES'
export const API_EDITOR_TEST_TEMPLATE = 'API_EDITOR_TEST_TEMPLATE'
export const API_EDITOR_DOWNLOAD_TEMPLATE = 'API_EDITOR_DOWNLOAD_TEMPLATE'
export const API_EDITOR_CREATE_FROM_TEMPLATE = 'API_EDITOR_CREATE_FROM_TEMPLATE'
export const API_EDITOR_ACCEPT_DOCUMENT = 'API_EDITOR_ACCEPT_DOCUMENT'
export const API_EDITOR_DECLINE_DOCUMENT = 'API_EDITOR_DECLINE_DOCUMENT'
export const API_EDITOR_GET_ACTIVITIES = 'API_EDITOR_GET_ACTIVITIES'

export const API_EDITOR_GET_USER = 'API_EDITOR_GET_USER';
export const API_EDITOR_UPDATE_USER_CREDENTIALS = 'API_EDITOR_UPDATE_USER_CREDENTIALS';
export const API_EDITOR_UPDATE_USER_ADDRESS = 'API_EDITOR_UPDATE_USER_ADDRESS';
export const API_EDITOR_UPDATE_USER_SURVEY = 'API_EDITOR_UPDATE_USER_SURVEY';

export const API_REVIEWER_GET_ALL_REVIEWS = 'API_REVIEWER_GET_ALL_REVIEWS'
export const API_REVIEWER_GET_REVIEW_BY_ID = 'API_REVIEWER_GET_REVIEW_BY_ID'
export const API_REVIEWER_ADD_REVIEW = 'API_REVIEWER_ADD_REVIEW'

export const API_AUTHOR_GET_ABSTRACTS = 'API_AUTHOR_GET_ABSTRACTS'
export const API_AUTHOR_DELETE_ABSTRACT = 'API_AUTHOR_DELETE_ABSTRACT'
export const API_AUTHOR_GET_ABSTRACT = 'API_AUTHOR_GET_ABSTRACT'
export const API_AUTHOR_GET_KEYWORDS = 'API_AUTHOR_GET_KEYWORDS'
export const API_AUTHOR_UPDATE_ABSTRACT = 'API_AUTHOR_UPDATE_ABSTRACT'
export const AUTHOR_CLEAR_STORED_ABSTRACT = 'CLEAR_STORED_ABSTRACT'
export const API_AUTHOR_RESUBMIT_ABSTRACT = 'API_AUTHOR_RESUBMIT_ABSTRACT'
export const AUTHOR_ADD_FIRST_AUTHOR_TO_ABSTRACT = 'AUTHOR_ADD_FIRST_AUTHOR_TO_ABSTRACT'
export const API_AUTHOR_DOWNLOAD_ABSTRACT = 'API_AUTHOR_DOWNLOAD_ABSTRACT'

export const API_GET_PUBLIC_SETTINGS = 'API_GET_PUBLIC_SETTINGS'
export const API_GEO_GET_COUNTRY = 'API_GEO_GET_COUNTRY'

export const EDITOR_SET_LAST_EDITED_USER_ID = 'EDITOR_SET_LAST_EDITED_USER_ID';
export const EDITOR_SET_LAST_EDITED_ABSTRACT_ID = 'EDITOR_SET_LAST_EDITED_ABSTRACT_ID';
