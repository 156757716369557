import {
    API_GEO_GET_COUNTRY,
    API_GET_USERINFO,
    API_LOGIN_USER,
    API_UPDATE_USER,
    API_UPDATE_USER_ADDRESS,
    API_UPDATE_USER_EVENTS,
    API_UPDATE_USER_SURVEY,
    EDIT_USER_FORM,
    LOGOUT,
    RETRIEVE_AUTH_FROM_STORAGE
} from "../constants/action-types";

const initialState = {
    token: '',
    user: {
        role: 'author'  // default role, needs to be provided
    },
    // userForm: {
    //     id: '',
    //     email: '',
    //     password: '',
    //     confirm: '',
    //     title: '',
    //     firstName: '',
    //     lastName: '',
    //     affiliation: '',
    //     acceptanceLetter: false,
    //     invitationLetter: false,
    //     certificate: false,
    //     address: '',
    //     country:'',
    // },
    country: ''
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${API_LOGIN_USER}_SUCCESS`:
            const auth = {
                token: action.payload.data.token,
                user: action.payload.data.user
            }
            localStorage.setItem('token', auth.token);
            // localStorage.setItem('user', JSON.stringify(auth.user));
            return { ...state, ...auth};
        case RETRIEVE_AUTH_FROM_STORAGE:
            const token = localStorage.getItem('token');
            // const user = JSON.parse(localStorage.getItem('user'));
            if(token){
                console.log('Auth reload from storage')
                return {...state, token};
            } else {
                return {...state};
            }
        case `${API_GET_USERINFO}_SUCCESS`:
        case `${API_UPDATE_USER}_SUCCESS`:
        case `${API_UPDATE_USER_ADDRESS}_SUCCESS`:
        case `${API_UPDATE_USER_EVENTS}_SUCCESS`:
        case `${API_UPDATE_USER_SURVEY}_SUCCESS`:
            return {
                ...state, user: action.payload.data
            };

        // case `${API_UPDATE_USER_EVENTS}_FAIL`:
        //     console.log(action.error.response.data)
        //     return {
        //         ...state, user: action.error.response.data.userToUpdate
        //     }
        case LOGOUT:
            localStorage.clear()
            return {...initialState};
        case EDIT_USER_FORM:
            return {...state, userForm: {...state.userForm, ...action.changedFields}}
        case `${API_GEO_GET_COUNTRY}_SUCCESS`:
            return {...state, country: action.payload.data.country}
        default:
            return state;
    }
}

export default userReducer;
