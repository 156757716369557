import React, {useEffect, useRef} from 'react';
import BasicLayout from "../../views/MainLayout";
import AbstractSubmissionForm from "../../forms/abstract/AbstractSubmission";
import {bindActionCreators} from "redux";
import {
    addFirstAuthor,
    clearAbstract,
    getAbstractById,
    resubmitAbstract,
    searchKeywords,
    submitAbstract,
    updateAbstract
} from "../../redux-actions/author";
import connect from "react-redux/es/connect/connect";
import {useHistory, useParams, withRouter} from "react-router-dom";
import {message} from "antd";

const AbstractsPage = ({type, abstract, addFirstAuthor, clearAbstract, getAbstract, updateAbstract, submitAbstract, resubmitAbstract, keywords, keywordsSearch, sessions, auth}) => {
    let formRef = useRef(null);
    let history = useHistory();
    const {id} = useParams();

    useEffect(() => {
        return () => {
            if (history.action === "POP")
                history.replace(history.location.pathname);
        }
    }, [history])

    useEffect(() => {
        if (id) getAbstract(id)
    }, [id]);     // only initial render!

    useEffect(() => {
        if(type === 'submit') {
            setFirstAuthor();
        }
    }, [type])

    useEffect(() => {
        return () => {
            clearAbstract();
        }
    }, [])


    const setFirstAuthor = () => {
        const {firstName, lastName, email, affiliation} = auth.user;
        addFirstAuthor(firstName, lastName, email, affiliation)
    };


//    let action;

    const handleSubmit = (e) => {
        e.preventDefault();

        // let action;
        // switch (props.type) {
        //     case 'submit':
        //         action = submitAbstract;
        //         break;
        //     case 'edit':
        //         action = updateAbstract;
        //         break;
        //     case 'resubmit':
        //         action = resubmitAbstract;
        //         break;
        //     default:
        //         action = submitAbstract     // otherwise AbstractSubmissionForm will yeld that action is undefined
        // }

        formRef.current.getForm().validateFields((err, values) => {
            if (err) return;
            const {file, ...meta} = values;
            delete meta['keys'];

            if(type === 'submit') {
                submitAbstract(file[0].originFileObj, meta).then((res => {
                    history.push('/abstracts');
                })).catch(err => message.error("The abstract could not be sent due to errors."));
            } else if(type === 'edit') {
                updateAbstract(file[0].originFileObj, meta, abstract.id).then((res => {
                    history.goBack();
                })).catch(err => message.error("The abstract could not be updated due to errors."));
            } else if(type === 'resubmit') {
                resubmitAbstract(file[0].originFileObj, meta, abstract.id).then((res => {
                    history.push('/abstracts');
                })).catch(err => message.error("The abstract could not be updated due to errors."));
            }
        });
    }




    return (
        <BasicLayout>
            <AbstractSubmissionForm
                type={type}
                abstract={abstract}
                onSubmit={handleSubmit}
                keywords={Array.from(new Set(keywords))}
                keywordsSearch={keywordsSearch}
                sessions={sessions}
                wrappedComponentRef={formRef}
            />
        </BasicLayout>
    );
};


const mapStateToProps = (state, ownProps) => ({
    abstract: state.author.abstract,
    auth: state.auth,
    keywords: state.author.keywords,
    sessions: state.settings.sessions
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getAbstract: getAbstractById,
        updateAbstract: updateAbstract,
        submitAbstract: submitAbstract,
        clearAbstract: clearAbstract,
        addFirstAuthor: addFirstAuthor,
        resubmitAbstract: resubmitAbstract,
        keywordsSearch: searchKeywords
    },
    dispatch,
);


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AbstractsPage))
