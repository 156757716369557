import React from "react";
import {Form, Input} from 'antd';
import styled from 'styled-components'

const layout = {
    labelCol: {span: 10},
    wrapperCol: {span: 12},
};

const StyledFormItem = styled(Form.Item)`
    margin-bottom: 0;
`


const PassportForm = React.forwardRef((props, ref) => {

    const {getFieldDecorator} = props.form;

    return (
        <Form layout="horizontal">
            <StyledFormItem label="Passport number" {...layout}>
                {getFieldDecorator('passport number', {})(<Input/>)}
            </StyledFormItem>
            <StyledFormItem label="Passport expiration date" {...layout}>
                {getFieldDecorator('passport expiration date', {})(<Input/>)}
            </StyledFormItem>
            <StyledFormItem label="Date of birth" {...layout}>
                {getFieldDecorator('date of birth', {})(<Input/>)}
            </StyledFormItem>
        </Form>
    )
});

export default Form.create()(PassportForm)
