import React from "react";
import {Button, Form, Icon, Upload} from "antd";

// const {Dragger} = Upload;


export const AbstractUpload = ({form, abstract}) => {

    // const [initFileList, setInitFileList] = useState([]);
    // useEffect(() => {
    //     if(abstract.file)
    //         setInitFileList()
    // }, [abstract]);

    const uploaderProps = {
        multiple: false,
        accept: '.doc, .docx, .pdf',
        beforeUpload: file => {
          return false
        },
        onRemove: file => {

        },
        onDownload: (file) => {

        },
        onChange: (file) => {
        }
    };

    const normFile = e => {
        if (Array.isArray(e)) {
            return e;
        }
        // Get last upload file only
        e.fileList = e.fileList.slice(-1);

        return e && e.fileList;
    };

    const {getFieldDecorator} = form;

    return (
        <Form.Item label="Abstract">
            {getFieldDecorator('file', {
                valuePropName: 'fileList',
                getValueFromEvent: normFile,
                // initialValue: abstract.file,
                rules: [{
                    required: true,
                    message: "Abstract is required",
                }],
            })(
        <Upload {...uploaderProps}>
            <Button>
                <Icon type="cloud-upload" /> Click to Upload
            </Button>
            {/*<p className="ant-upload-drag-icon">*/}
            {/*    <Icon type="cloud-upload"/>*/}
            {/*</p>*/}
            {/*<p className="ant-upload-text">Abstract</p>*/}
            {/*<p className="ant-upload-hint">Click to upload an abstract.</p>*/}
        </Upload>
            )}
        </Form.Item>
    );
};
