import {
    API_REVIEWER_ADD_REVIEW,
    API_REVIEWER_GET_ALL_REVIEWS,
    API_REVIEWER_GET_REVIEW_BY_ID
} from "../constants/action-types";

export const reviewerGetReviews = () => {
    return {
        type: API_REVIEWER_GET_ALL_REVIEWS,
        payload: {
            request: {
                url: `/reviews`,
                method: 'get'
            }
        },
    }
}

export const reviewerGetReviewById = (id) => {
    return {
        type: API_REVIEWER_GET_REVIEW_BY_ID,
        payload: {
            request: {
                url: `/reviews/${id}`,
                method: 'get'
            }
        },
    }
}

export const reviewerAddReview = (review) => {
    return {
        type: API_REVIEWER_ADD_REVIEW,
        payload: {
            request: {
                url: `/reviews/${review.id}`,
                method: 'put',
                data: {
                    comment: review.comment,
                    decision: review.decision
                }
            }
        },
    }
}
