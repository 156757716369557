import React, {Component} from 'react';
import 'antd/dist/antd.css';
import {BrowserRouter} from 'react-router-dom'
import Routing from "./Routing";
import configureStore from "./redux-store";
import {Provider} from "react-redux";
import {getCurrentUser, retrieveAuthFromStorage} from "./redux-actions/auth";
import {getPublicSettings} from "./redux-actions/settings";
import {Helmet} from "react-helmet";
import CacheBuster from './CacheBuster'

import config from "./config";

const store = configureStore()
store.dispatch(retrieveAuthFromStorage())
store.dispatch(getPublicSettings())

if (store.getState().auth.token)
    store.dispatch(getCurrentUser())


class App extends Component {
    render() {
        return (
            <CacheBuster>
                {({loading, isLatestVersion, refreshCacheAndReload}) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload();
                    }
                    else return (
                        <Provider store={store}>
                            <Helmet>
                                <title>{config.title}</title>
                            </Helmet>

                            <BrowserRouter>
                                <Routing/>
                            </BrowserRouter>
                        </Provider>
                    );
                }}
            </CacheBuster>
        );
    }
}

export default App;
