import React from 'react';
import {Button, Card, Form, Input} from 'antd';
import PropTypes from 'prop-types'
import {withRouter} from "react-router-dom";

const FormItem = Form.Item;

class ReviewResponse extends React.Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.action({
                    comment: values.comment,
                }).then((response) => {
                    this.props.history.push('/reviews')
                })
            }
        });
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = {
            labelCol: {
                sm: {span: 24},
            },
            wrapperCol: {
                sm: {span: 24},
            },
        };
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                sm: {span: 22},
            },
        };

        return (
            <div>
                <Card title="Response" style={{...this.props.style}}>
                    <Form onSubmit={this.handleSubmit} layout='vertical'>
                        <FormItem
                            {...formItemLayout}
                            label="Comments"
                        >
                            {getFieldDecorator('comment', {
                                rules: [
                                    {required: true, message: 'Please write a message!'},
                                ],
                            })(
                                <Input.TextArea rows={3}/>
                            )}
                        </FormItem>
                        <FormItem {...formItemLayoutWithOutLabel}>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </FormItem>

                    </Form>
                </Card>
            </div>
        );
    }
}

ReviewResponse.propTypes = {
    action: PropTypes.func.isRequired,
    review: PropTypes.object.isRequired
}

ReviewResponse = Form.create()(ReviewResponse);
ReviewResponse = withRouter(ReviewResponse)
export default ReviewResponse

