import React, {Component} from 'react';
import RegistrationForm from "../forms/user/Registration";
import NoMenuLayout from "../views/NoMenuLayout";
import {bindActionCreators} from "redux";
import {suggestCountry, userFormEdit, userRegister} from "../redux-actions/auth";
import connect from "react-redux/es/connect/connect";
import styles from "../views/NoMenuLayout.module.scss";

class Registration extends Component {

    componentDidMount() {
        this.props.suggestCountry()
    }

    render() {
        const {userRegister, userFormEdit, formContent, country} = this.props

        return (
            <NoMenuLayout>
                <div className={`${styles.centerPanel} ${styles.panelRegistration}`}>
                    <div className={styles.panel}>
                        <RegistrationForm userRegister={userRegister} onFormEdit={userFormEdit}
                                          formContent={formContent} country={country}/>
                    </div>
                </div>
            </NoMenuLayout>
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    formContent: state.auth.userForm,
    country: state.auth.country
})

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        userRegister,
        userFormEdit,
        suggestCountry
    },
    dispatch,
)


Registration = connect(mapStateToProps, mapDispatchToProps)(Registration)
export default Registration;
