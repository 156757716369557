import React from 'react'
import {Button, Col, Form, Input, Radio, Row, Tooltip} from "antd";
import _ from "lodash-es";
import styled from 'styled-components'
import notAllowAllCapital from "../../validators/notAllowAllCapital";
import {If} from "babel-plugin-jsx-control-statements/macro"

const FormItem = styled(Form.Item)`
  margin-bottom: 0;
`

const ButtonGroup = styled(Button.Group)`
  padding-top: 2px;
`

const RightAlignCol = styled(Col)`
  text-align: right;
`

const StyledRadio = styled(Radio)`
  padding-top: 5px;
`

const Author = ({form, index, count, author, isSpeaker, onRemove, onMoveDown, onMoveUp, onSetSpeaker}) => {
    const {getFieldDecorator} = form;

    return (
        <Row gutter={19}>
            <Col lg={1} xs={4}>
                <ButtonGroup>
                    <Button size="small" icon="caret-up" disabled={index === 0} onClick={onMoveUp}/>
                    <Button size="small" icon="caret-down" disabled={index === (count - 1)} onClick={onMoveDown}/>
                </ButtonGroup>
            </Col>
            <Col lg={4} xs={10}>
                <FormItem
                    required={false}
                >
                    {getFieldDecorator(`authors[${index}].firstName`, {
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{
                            required: true,
                            whitespace: true,
                            message: "Please input author first name.",
                        },
                            {
                                validator: notAllowAllCapital
                            }
                        ],
                        initialValue: _.get(author, 'firstName', '')
                    })(
                        <Input placeholder="First name"/>
                    )}
                </FormItem>
            </Col>
            <Col lg={4} xs={10}>
                <FormItem
                    required={false}
                >
                    {getFieldDecorator(`authors[${index}].lastName`, {
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{
                            required: true,
                            whitespace: true,
                            message: "Please input author's last name.",
                        },
                            {
                                validator: notAllowAllCapital
                            }
                        ],
                        initialValue: _.get(author, 'lastName', '')
                    })(
                        <Input placeholder="Last name"/>
                    )}
                </FormItem>
            </Col>
            <Col lg={5} xs={10}>
                <FormItem
                    required={false}
                >
                    {getFieldDecorator(`authors[${index}].email`, {
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{
                            required: true,
                            whitespace: true,
                            type: 'email',
                            message: "Please input author's email",
                        }],
                        initialValue: _.get(author, 'email', '')
                    })(
                        <Input placeholder="Email"/>
                    )}
                </FormItem>
            </Col>
            <Col lg={8} xs={10}>
                <FormItem
                    required={false}
                >
                    {getFieldDecorator(`authors[${index}].affiliation`, {
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{
                            required: true,
                            whitespace: true,
                            message: "Please input author's affiliation.",
                        }],
                        initialValue: _.get(author, 'affiliation', '')
                    })(
                        <Input placeholder="Affiliation"/>
                    )}
                </FormItem>
            </Col>

            <RightAlignCol lg={1} xs={2}>
                <Tooltip placement="right" title="Speaker">
                    <FormItem>
                        <StyledRadio value={index} checked={isSpeaker} onClick={onSetSpeaker}/>
                    </FormItem>
                </Tooltip>
            </RightAlignCol>

            <RightAlignCol lg={1} xs={2}>
                <If condition={count > 1}>
                    <Tooltip placement="topLeft" title="Remove author">
                        <Button icon="delete" shape="circle" onClick={onRemove}/>
                    </Tooltip>
                </If>
            </RightAlignCol>
        </Row>
    )
}

export default Author;
