import React from 'react';
import LoginForm from "../forms/Login";
import NoMenuLayout from "../views/NoMenuLayout";
import styles from '../views/NoMenuLayout.module.scss'

// import {Alert} from "antd";


function Login(props) {
    return (
        <div>
            <NoMenuLayout>
                <div className={`${styles.centerPanel} ${styles.panelLogin}`}>

                        {/*<Alert style={{marginTop: 5, marginBottom: 20}}*/}
                        {/*    message="System Maintenance"*/}
                        {/*    description="We are sorry, our system is temporarily unavailable. Please try again later or contact us by email."*/}
                        {/*    type="info"*/}
                        {/*    showIcon*/}
                        {/*/>*/}
                        <div className={styles.panel}>
                            <LoginForm/>
                        </div>

                        {/*<Alert*/}
                        {/*    message="Announcement"*/}
                        {/*    description={<div>The new deadline for abstracts submission is <strong>30.04.2019</strong>*/}
                        {/*    </div>}*/}
                        {/*    type="info"*/}
                        {/*    showIcon>*/}
                        {/*</Alert>*/}


                </div>

            </NoMenuLayout>


        </div>
    );
}

Login.propTypes = {};
Login.defaultProps = {};

export default Login;
