import React from 'react';
import BasicLayout from "../../views/MainLayout";
import Reviews from "../../lists/Reviews";

function ReviewsList(props) {
    return (
        <BasicLayout>
            <Reviews/>
        </BasicLayout>
    );
}

ReviewsList.propTypes = {};
ReviewsList.defaultProps = {};

export default ReviewsList;
