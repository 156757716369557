import chroma from "chroma-js";
import styles from "./UserMapPanel.module.scss";
import React from "react";

export const palette = (max) => {
    return chroma.scale(["#AA0000",
        "#D00000", "#F70000", "#FF1D00", "#FF4400", "#FF6A00", "#FF9000",
        "#FFB700", "#FFDD00", "#FFFF00", "#FFFF00", "#FFFF00", "#BDFF0C",
        "#73FF1A", "#3FFA36", "#16F45A", "#00D08B", "#0087CD", "#0048FA",
        "#0024E3"]).domain([max, 1]);
}

export const MapLegendBar = ({max}) => {
        const dmin = 0;
        const dmax = max;
        let s = []
        const localPalette = palette(max);
        for (let i = 0; i <= 100; i++) {
            s.push(<span
                key={`lb_step_${i}`}
                className={styles.gradStep}
                style={{backgroundColor: localPalette(dmin + i / 100 * (dmax - dmin))}}
            />);
        }
        s.push(<span key="keyMin" className={styles.domainMin}>{1}</span>);
        s.push(<span key="keyCenter" className={styles.domainMed}>{Math.ceil((dmin + dmax) * 0.5)}</span>);
        s.push(<span key="keyMax" className={styles.domainMax}>{max}</span>);
        return <div className={styles.gradient}>{s}</div>;
}
